@charset "utf-8";
body { 
    position: relative;
    margin: 0px;
    padding: 0px;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: normal;
    font-family: 'product_sansregular';
    color:#000000;
    background-color: #ffffff;
   
}
body.body-sticky-top { margin-top: 80px; }
a, span, i {display: inline-block; }
a,a:hover,a:focus, .primary-menu a:hover, .primary-menu a:focus, .primary-menu .current_page_ancestor,#site-footer a:focus, #site-footer a:hover,.primary-menu li.current-menu-item > a, .primary-menu li.current-menu-item > .link-icon-wrapper > a,.pagination a:hover,.pagination a,.pagination a:focus{text-decoration: none;cursor: pointer;}
button:focus, a:focus,.slick-initialized .slick-slide:focus,.contact-Us-Form-box input.wpcf7-submit:focus{outline:0;}


@font-face {
    font-family: 'nimbus_sans_d_otlight';
    src: url('../fonts/nimbus_sans_light-webfont.woff') format('woff2'),
         url('../fonts/nimbus_sans_light-webfont.woff') format('woff'),
         url('../fonts/nimbus_sans_light-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'product_sansbold';
    src: url('../fonts/product_sans_bold-webfont.woff2') format('woff2'),
         url('../fonts/product_sans_bold-webfont.woff') format('woff'),
         url('../fonts/product_sans_bold-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'product_sansregular';
    src: url('../fonts/product_sans_regular-webfont.woff2') format('woff2'),
         url('../fonts/product_sans_regular-webfont.woff') format('woff'),
         url('../fonts/product_sans_regular-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

/*-- Utility --*/
.font-bold{font-family: 'product_sansbold';}
.font-regular{font-family: 'product_sansregular';}
.font-light{font-family: 'nimbus_sans_d_otlight';}
h2,.h2{
    font-size: 40px;
    line-height: 48px;
    color: #000000;
    font-family: 'product_sansbold';
    margin-bottom:0;
}
h3,.h3{
    font-size: 36px;
    line-height: 43px;
    color: #000000;
    font-family: 'product_sansbold';
    margin-bottom:0;
}
h4,.h4{
    font-size: 32px;
    line-height: 38px;
    color: #000000;
    font-family: 'product_sansbold';
    margin-bottom:0;
}
h5,.h5{
    font-size:26px;
    line-height:26px;
    margin-bottom:0;
}
p,.p{
   
    line-height: 26px;
    color: #63666A;
    margin-bottom: 0;
}
.text-18{
    font-size:18px;
    line-height:22px;
}
.text-16,.featured-rent-radio label span.text-16{
    font-size:16px;
    line-height:20px;
}
.text-15{
    font-size:15px;
    line-height:20px;
}
.text-12,.featured-rent-radio label p.text-12{
    font-size:12px;
    line-height:15px;
}
.opacity-50{opacity:0.5;}
.opacity-100{opacity:1;}
.text-black{color:#000000;}
.text-red,.booking-details-listing-right.text-red{color:#C8102E;}
.text-blue{color:#006FF7;}

.bg-red{background-color:#C8102E;}


h2.border-botom, .h2.border-botom{
    padding-bottom: 20px;
    margin-bottom: 15px;
    position: relative;
    display: inline-block;
}
h2.border-botom:after, .h2.border-botom:after {
    content: '';
    position: absolute;
    width: 72px;
    height: 3px;
    background-color: #C8102E;
    bottom: 0;
    left: 0;
}

.ptb-100{ padding:100px 0;}
.ptb-80{ padding:80px 0;}
.ptb-50{ padding:80px 0;}
.mb-50{margin-bottom:50px;}



.primary-bttn {
    font-size: 16px;
    line-height: 16px;
    color: #4287f5;
    background-color:transparent;
    font-family: 'product_sansregular';
    font-weight: 600;
    border: 2px solid #4287f5;
    padding: 17px 45px;
    display: inline-block;
    border-radius: 100px;
    -webkit-transition:0.3s all ease-in-out;
    -o-transition:0.3s all ease-in-out;
    transition:0.3s all ease-in-out;
}
.primary-bttn:hover,.primary-bttn.primary-invert-bttn{
    color: #ffffff;
    background-color:#4287f5;
}
.primary-bttn.primary-invert-bttn:hover {
    background-color:transparent;
    color:#C8102E;
}

.bg-grey{background-color:#F8F8F8;}

/*-- Header --*/
header.header-nav {background-color:#ffffff;}
header.header-nav ul.navbar-nav > li > a {
    font-size: 18px;
    line-height: 22px;
    color: #000000;
    padding:29px 15px;
}
.header-nav.header-sticky {
    position: fixed;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    z-index:999;
    box-shadow: 0 1px 10px rgba(0,0,0,.1);
    -moz-box-shadow: 0 1px 10px rgba(0,0,0,.1);
    -webkit-box-shadow: 0 1px 10px rgb(0 0 0 / 10%);
    -o-box-shadow: 0 1px 10px rgba(0,0,0,.1);
    -ms-box-shadow: 0 1px 10px rgba(0,0,0,.1);
}
header.header-nav ul.navbar-nav li + li { margin-left: 20px; }
.navbar-expand-lg .navbar-nav { -webkit-box-align: center; -ms-flex-align: center; align-items: center; }
.dropdown.country-dropdown button {
    border: 0;
    background: transparent;
    padding: 0;
    position: relative;
    color: #000000;
    opacity: 0.5;
    font-size: 18px;
    line-height: 22px;
}
.dropdown.country-dropdown button:before {
    content: '';
    background-image: url('../images/world-lang.svg');
    width: 20px;
    height: 20px;
    display: inline-block;
    vertical-align: text-bottom;
    margin-right: 4px;
}
.login-register-wrap {
    flex-wrap:wrap;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    position: relative;
    font-size: 18px;
    line-height: 22px;
    color: #C8102E;
    font-weight: 600;
    margin-left: 24px;
}
.login-register-wrap:before {
    content: "\f2be";
    font-family: 'FontAwesome';
    color: #C8102E;
    margin-right: 10px;
}
.login-register-wrap a { color: #C8102E; }
.login-register-wrap a + a {
    margin-left: 5px;
    padding-left: 5px;
    border-left: 2px solid #C8102E;
}
.reguster-right-wrap { display: -webkit-box; display: -ms-flexbox; display: flex; -ms-flex-wrap: wrap; flex-wrap: wrap;align-items: center;}
/*-- Banner Section --*/
.banner-section { position: relative;background-color:#000000;}
.banner-section-content {
    position: absolute;
    width: 100%;
    top: 40px;
}
.banner-slider img {
    max-width: 100%;
    height: auto;
}
.banner-slider-section .slick-arrow {
    position: absolute;
    left:100px;
    right: auto;
    color: #ffffff;
    z-index: 999;
    font-size: 51px;
    line-height: 51px;
    opacity: 0.2;
    cursor: pointer;
    top: 50%;
    -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
            transform: translateY(-50%);
    -webkit-transition:0.3s all ease-in-out;
    -o-transition:0.3s all ease-in-out;
    transition:0.3s all ease-in-out;
}
.banner-slider-section .slick-arrow.next-arrow {
    left: auto;
    right:100px;
}
.banner-slider-section .slick-arrow:hover{opacity:0.6;}
.down-arrow {
    height: 220px;
    width: 220px;
    background-color: #000000;
    margin: 0 auto;
    border-radius: 50%;
    color: #ffffff;
    font-size: 25px;
    -webkit-transition: 0.3s all ease;
    -o-transition: 0.3s all ease;
    transition: 0.3s all ease;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    margin-top: -180px;
}
.down-arrow:hover{ color: #ffffff; }
.down-arrow i { padding: 0 10px 13px; }
.banner-section .nav-tabs a {
    border: 0;
    padding: 10px 0;
    font-size: 18px;
    line-height: 22px;
    background-color: transparent;
    color: #ffffff;
    position: relative;
}
.banner-section .nav-tabs a:after {
    content: '';
    position: absolute;
    width:0;
    height: 2px;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    background-color: #ffffff;
    -webkit-transition: 0.3s all ease-in-out;
    -o-transition: 0.3s all ease-in-out;
    transition: 0.3s all ease-in-out;
}
.banner-section .nav-tabs a:hover::after,.banner-section .nav-tabs a.active::after{
    width:24px;
}
.nav-tabs .nav-link.active{background-color: transparent;color: #ffffff;}
.banner-section .nav-tabs a + a { margin-left: 80px; }
.banner-section .tab-content {
    margin-top: 30px;
}
.banner-section .tab-content .tab-pane {
    position: relative;
}
.search-box-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    width: 100%;
    max-width: 1480px;
    border-radius: 100px;
    background-color: #ffffff;
    margin: 0 auto;
    padding: 10px 15px;
    position: relative;
}
.search-box-wrap button.search-bttn {
    border: 0;
    background-color: #C8102E;
    color: #ffffff;
    font-size: 18px;
    line-height: 22px;
    padding: 14px 31px;
    border-radius: 80px;
    margin-left: auto;
}
.search-box-wrap button.search-bttn i { font-size: 16px; line-height: 22px;margin-right: 15px;}
.search-box-wrap .select-wrap-dropdown {color: #000000;-webkit-box-flex: 1;-ms-flex: auto;flex: auto;}
.search-box-wrap .select-wrap-dropdown label { display: block;margin-bottom: 0;}
.search-box-wrap .select-wrap-dropdown a {
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
    color: #000000;
    cursor: pointer;
}

.widget-time
{
    color: #C8102E !important;
    padding-left: 8px;
}

.search-box-wrap .select-wrap-dropdown .pickup-return-date-wrap { display: -webkit-box; display: -ms-flexbox; display: flex; -ms-flex-wrap: wrap; flex-wrap: wrap; }
.search-box-wrap .select-wrap-dropdown:first-child { max-width: 190px;padding-right:24px;}
.search-box-wrap .select-wrap-dropdown:first-child > label, .search-box-wrap .select-wrap-dropdown:first-child > .dropdown-toggle{padding-left:40px;}
.search-box-wrap .select-wrap-dropdown:nth-child(1) { max-width: 625px;}
.search-box-wrap .select-wrap-dropdown:nth-child(3) { max-width: 218px;padding-left: 24px;}
.search-box-wrap .select-wrap-dropdown:nth-child(2) { padding: 0 25px; max-width:472px; }
.search-box-wrap .search-box-wrap.promocode-offer-wrap input::-webkit-input-placeholder {
    padding: 0;
    border: 0;
    font-size: 18px;
    line-height: 22px;
    font-weight: bold;
    color: #C8102E;
    width:100%;
}
.search-box-wrap .search-box-wrap.promocode-offer-wrap input::-moz-placeholder {
    padding: 0;
    border: 0;
    font-size: 18px;
    line-height: 22px;
    font-weight: bold;
    color: #C8102E;
    width:100%;
}
.search-box-wrap .search-box-wrap.promocode-offer-wrap input:-ms-input-placeholder {
    padding: 0;
    border: 0;
    font-size: 18px;
    line-height: 22px;
    font-weight: bold;
    color: #C8102E;
    width:100%;
}
.search-box-wrap .search-box-wrap.promocode-offer-wrap input::-ms-input-placeholder {
    padding: 0;
    border: 0;
    font-size: 18px;
    line-height: 22px;
    font-weight: bold;
    color: #C8102E;
    width:100%;
}
.search-box-wrap .search-box-wrap.promocode-offer-wrap input,.search-box-wrap .search-box-wrap.promocode-offer-wrap input::placeholder {
    padding: 0;
    border: 0;
    font-size: 18px;
    line-height: 22px;
    font-weight: bold;
    color: #C8102E;
    width:100%;
}
.select-wrap-dropdown.select-wrap-section-main {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
}
.select-wrap-dropdown.select-wrap-section-main .select-wrap-section {
    max-width: calc(100% - 148px);
    padding-left: 20px;
    display: block;
    margin-right:auto;
}
.more-car-slider-wrap .slick-list {
    max-width: 678px;
    margin: 0 auto;
    overflow: visible;
}
.select-wrap-dropdown.select-wrap-section-main > * { width: 100%; }
.select-wrap-dropdown.select-wrap-section-main .return-pickup-wrap {
    max-width: 148px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}

.select-wrap-dropdown.select-wrap-section-main .return-pickup-wrap label {
    font-size: 12px;
    line-height: 14px;
    color: #000000;
    font-weight: bold;
    max-width: 80px;
    margin-left: 10px;
    cursor: pointer;
}
.custom-checkbox-2-wrap input { position: absolute; z-index: -1; opacity: 0; }
.custom-checkbox-2-wrap .custom-checkbox-2 {
    width: 24px;
    height: 24px;
    border: 2px solid #C8102E;
    border-radius: 50%;
    position: relative;
}
.custom-checkbox-2-wrap .custom-checkbox-2::before {
    content: '';
    position: absolute;
    left: 8px;
    top: 4px;
    width: 5px;
    height: 10px;
    border: solid transparent;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate( 45deg );
        -ms-transform: rotate( 45deg );
            transform: rotate( 45deg );
}
.custom-checkbox-2-wrap input:checked ~ .custom-checkbox-2::before {
    border-color: #c8102e;
}
.select-wrap-dropdown.select-wrap-section-main .dropdown-toggle::after{display:none;}
.search-box-wrap .search-box-wrap.promocode-offer-wrap label { padding-left: 0; }
.search-box-wrap.promocode-offer-wrap { padding: 0;}
.search-box-wrap.promocode-offer-wrap .select-wrap-dropdown { padding-right: 0; }
.search-box-wrap .select-wrap-dropdown:not(:first-child) { border-left: 1.5px solid rgba(0, 0, 0, 0.2); }
.search-box-wrap .select-wrap-dropdown .pickup-return-date-wrap > div { width: 50%; text-align:center; }
.search-box-wrap .select-wrap-dropdown .pickup-return-date-wrap .dropdown-toggle::after{display:none;}
.pickup-return-date-right{position: relative;}
.pickup-return-date-right:before {
    content: '';
    width: 6px;
    height: 1px;
    background-color: #000000;
    position: absolute;
    left: -3px;
    top: 10px;
}
.search-box-wrap .select-wrap-dropdown > a {
    width: 100%;
    overflow: hidden;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}

/*-- Download App Section --*/
.download-app-wrap a,.download-app-wrap .download-app-anchor {
    font-size: 18px;
    line-height: 24px;
    color: #000000;
    font-weight: 600;
}

.download-store-app + .download-store-app { margin-left: 15px; }
.download-app-wrap a span.download-text { margin: 0 14px; }
.download-app-wrap .download-app-anchor span {
    color: #63666A;
    font-size: 14px;
    line-height: 20px;
    display: block;
    font-weight: normal;
}
.download-app-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.download-app-section { padding:38px 0;}
.download-app-wrap > a + a { margin-left: 85px; }
.download-app-wrap a i { font-size: 22px; line-height: 22px; }
.download-app-wrap .download-app-anchor {
    margin-left: auto;
    border-left: 1px solid #D7DDE4;
    padding:16px 0 16px 60px;
    margin-right: 96px;
}
.download-function-anchor { margin: 0 auto; }
.download-app-wrap .download-function-anchor:first-child { margin-left: 0; }

/*-- Special offers --*/
.special-offer-box .special-offer-box-wrap {
    width: 100%;
    min-height: 277px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    -webkit-transition: 0.3s all ease-in-out;
    -o-transition: 0.3s all ease-in-out;
    transition: 0.3s all ease-in-out;
    position: relative;
    overflow: hidden;
}
.special-offer-title{
    padding: 10px 20px;
    font-size: 22px;
    line-height: 26px;
    color:#ffffff;
    z-index: 9;
    margin-top: auto;
    margin-bottom: 0;
}
.special-offer-box .special-offer-box-wrap .special-offer-image {
    width: 100%;
    height: 100%;
    background-size:cover;
    background-repeat: no-repeat;
    position:absolute;
    left:0;
    top:0;
    -webkit-transition: 0.5s all ease-in-out;
    -o-transition: 0.5s all ease-in-out;
    transition: 0.5s all ease-in-out;
    background-position: center;
}
.special-offer-box .special-offer-box-wrap:hover .special-offer-image { -webkit-transform: scale(1.1); -ms-transform: scale(1.1); transform: scale(1.1); }
.special-offer-box .special-offer-box-wrap.special-offer-big { min-height: 570px; }
.special-offer-section .form-row { margin-right: -10px; margin-left: -10px; }
.special-offer-section .form-row > div{padding-right: 10px;padding-left: 10px;}
.special-offer-content {
    color: #000000;
    z-index: 9;
    font-size: 20px;
    line-height: 26px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    background-color: #ffffff;
    width: 100%;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 10px 20px;
    max-height:100%;
    overflow: hidden;
    -webkit-transition: 0.3s all ease-in-out;
    -o-transition: 0.3s all ease-in-out;
    transition: 0.3s all ease-in-out;
}
.special-offer-content i { font-style: normal; } 
.special-offer-content i.fa {
    width: 36px;
    height: 36px;
    background-color: #C8102E;
    border-radius: 50%;
    line-height: 36px;
    text-align: center;
    color: #ffffff;
    font-size: 26px;
    margin-left: auto;
}
.special-offer-content i:first-child { max-width:calc(100% - 40px); }
.special-offer-box-wrap .special-offer-link {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index:999;
}
.discount-sticker {
    position:absolute;
    left:0;
    top:0;
    z-index: 9;
    color: #ffffff;
    font-size: 16px;
    line-height: 16px;
    font-family: 'product_sansbold';
    background-color: #C8102E;
    display: inline-block;
    width: auto;
    padding: 10px 15px;
}
.discount-sticker span {
    font-size: 24px;
    line-height: 24px;
}
.special-offer-figcaption {
    position: absolute;
    width: 100%;
    top: auto;
    bottom: 0;
    -webkit-transition: -webkit-transform 0.35s;
    transition: -webkit-transform 0.35s;
    -o-transition: transform 0.35s;
    transition: transform 0.35s;
    transition: transform 0.35s, -webkit-transform 0.35s;
    -webkit-transform: translate3d(0,calc(100% - 46px),0);
    transform: translate3d(0,calc(100% - 46px),0);
}
.special-offer-box .special-offer-box-wrap:hover .special-offer-figcaption {
    -webkit-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
}



.more-car-bttns {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}
.more-car-bttns .primary-bttn.primary-invert-bttn { margin: 0 20px; }
.more-cars-slider p {
    font-size: 18px;
    line-height: 21px;
    max-width: 399px;
    margin: 12px auto 0;
}
.more-car-slider-wrap .slide-arrow{
    width: 56px;
    height: 56px;
    background-color: #ffffff;
    color: #C8102E;
    -webkit-box-shadow: 0px 3px 16px #00000029;
            box-shadow: 0px 3px 16px #00000029;
    border-radius: 50%;
    line-height: 56px;
    text-align: center;
    font-size: 40px;
    cursor: pointer;
    -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
            transform: translateY(-50%);
    top: 50%;
    position: absolute;
    z-index: 99;
    left: 0;
    right:auto;
}
.more-car-slider-wrap .slide-arrow i{line-height:56px;}
.more-car-slider-wrap .slide-arrow.next-arrow { left: auto; right: 0; }

.more-car-slider-wrap .more-cars-slider h3,.more-car-slider-wrap .more-cars-slider .more-car-bttns1,.more-car-slider-wrap .more-cars-slider h4,.more-car-slider-wrap .more-cars-slider p {
    opacity: 0;
    visibility: hidden;
    -webkit-transition: 0.3s all ease-in-out;
    -o-transition: 0.3s all ease-in-out;
    transition: 0.3s all ease-in-out;
}
.more-car-slider-wrap .slick-center .more-cars-slider h3,.more-car-slider-wrap .slick-center .more-cars-slider .more-car-bttns1,.more-car-slider-wrap .slick-center .more-cars-slider h4,.more-car-slider-wrap .slick-center .more-cars-slider p {
    opacity: 1;
    visibility: visible;
}

.more-car-slider-wrap .slick-current .more-car-bttns {
    opacity: 1;
    visibility: visible;
}
.more-cars-slider {text-align: center;margin:0;}
.more-car-slider-wrap ul.slick-dots {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    list-style: none;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    padding: 0;
    margin:35px 0 0;
}
.more-car-slider-wrap ul.slick-dots li button {
    width: 32px;
    height: 4px;
    font-size: 0;
    line-height: 0;
    border: 0;
    background-color: #C4C4C4;
    border-radius:50px;
}
.more-car-slider-wrap ul.slick-dots li {line-height: 0;font-size: 0;margin: 0 4px;}
.more-car-slider-wrap ul.slick-dots li.slick-active button { background-color: #C8102E; }
.more-cars-slider img { margin:0; -webkit-transform: scale(0.7); -ms-transform: scale(0.7); transform: scale(0.7);-webkit-transition: 0.3s all ease;-o-transition: 0.3s all ease;transition: 0.3s all ease;}
.more-car-slider-wrap .slick-current img {-webkit-transform: scale(1);-ms-transform: scale(1);transform: scale(1);}
.more-car-slider-wrap { margin-top: 50px;overflow: hidden;}
.more-car-slider-wrap img {
    max-width: 100%;
    height: auto;
}


.more-car-slider-wrap .slick-list.draggable {
    max-width: 678px;
    margin: 0 auto;
    overflow: visible;
}
.more-car-slider-wrap:before,.more-car-slider-wrap:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 200px;
    z-index:9;
    background: rgb(255,255,255);
    background: -o-linear-gradient(left , rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
    background: -webkit-gradient(linear , left top, right top , from(rgba(255,255,255,1)), to(rgba(255,255,255,0)));
    background: linear-gradient(90deg , rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
}
.more-car-slider-wrap:after{
    right:0;
    left:auto;
    background: rgb(255,255,255);
    background: -o-linear-gradient(left , rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
    background: -webkit-gradient(linear , left top, right top , from(rgba(255,255,255,0)), to(rgba(255,255,255,1)));
    background: linear-gradient(90deg , rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
}



.product-nav-tabs {
    border-bottom-color: rgba(153, 157, 164, 0.2);
    padding-bottom: 25px;
    margin-bottom: 35px;
}
.product-nav-tabs .nav-link.active { background-color: #C8102E; color: #ffffff; }
.product-nav-tabs .nav-link {
    border: 0;
    font-size: 18px;
    line-height: 21px;
    font-weight: normal;
    padding: 13px 36px;
    border-radius: 100px;
    color:#63666A;
}
.lease-left p { font-size: 16px; line-height: 24px;margin-bottom: 24px;}
.lease-left h3 { margin-bottom: 26px; }

.lease-wrapper .lease-left {
    -ms-flex: 0 0 46%;
    -webkit-box-flex: 0;
            flex: 0 0 46%;
    max-width: 46%;
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}
.lease-wrapper .lease-right {
    -ms-flex: 0 0 51%;
    -webkit-box-flex: 0;
            flex: 0 0 51%;
    max-width: 51%;
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}



/*-- Footer --*/
footer {
    background-color: #F8F8F8;
    padding: 53px 0 60px;
}
ul.footer-nav-wrap a {
    font-size: 16px;
    line-height: 20px;
    color: #000000;
    -webkit-transition:0.3s all ease-in-out;
    -o-transition:0.3s all ease-in-out;
    transition:0.3s all ease-in-out;
}
ul.footer-nav-wrap .footer-nav-title {
    font-size: 20px;
    line-height: 24px;
    color: #C8102E;
}
.footer-nav-wrap {
    margin: 0;
    padding: 0;
    list-style: none;
}
.footer-nav-wrap li:first-child { margin-bottom: 14px; }
.footer-nav-wrap li + li { margin-top: 8px; }
.footer-mobile {
    display: block;
    width: 100%;
    font-size: 16px;
    line-height: 20px;
    color: #000000;
    margin-bottom: 8px;
    -webkit-transition:0.3s all ease-in-out;
    -o-transition:0.3s all ease-in-out;
    transition:0.3s all ease-in-out;
}
.footer-mobile:hover,ul.footer-nav-wrap a:hover{
    color: #000000;
}
ul.social-media-footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    margin-bottom: 0;
    margin-top:20px;
}
ul.social-media-footer a {
    font-size: 20px;
    line-height: 20px;
    color: #C8102E;
}
ul.social-media-footer li + li { margin-left: 20px; }




ul.info-list-wrap {
    margin-bottom: 0;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
}
ul.info-list-wrap a,.info-wrapper p,.info-wrapper a {
    font-size: 14px;
    line-height: 16px;
    color: #000000;
}
.copyright-footer {
    background-color: #F8F8F8;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    padding: 9px 0;
}
.info-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}
ul.info-list-wrap li + li { margin-left: 30px; }



/*-- Location Section --*/
.find-location-section{
    background-image:url('../images/dollar-location.png');
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}
.find-location-section:before{
    content: '';
    position:absolute;
    width:100%;
    height:100%;
    /* background-color:rgba(0, 0, 0, 0.5); */
    top:0;
    left:0;
    z-index:1;
}
.find-location-section h2,.find-location-section h5,.find-location-section p{color:#ffffff;}
.location-wrap {
    position: relative;
    z-index: 2;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
}
.find-location-bttn {
    font-size: 18px;
    line-height: 18px;
    color: #FFFFFF;
    background-color: #C8102E;
    padding: 18px 48px;
    border-radius: 100px;
    -webkit-transition:0.3s all ease-in-out;
    -o-transition:0.3s all ease-in-out;
    transition:0.3s all ease-in-out;
}
.find-location-bttn:hover{
    color: #C8102E;
    background-color: #FFFFFF;
}


.what-new-box {
    position: relative;
    overflow: hidden;
}
.what-new-box img {
    width: 100%;
    height: auto;
}
.what-new-box .what-new-box-content {
    position: absolute;
    bottom: 0;
    padding: 16px;
}
.what-new-box .what-new-box-content h3 {
    color: #ffffff;
    font-size: 24px;
    line-height: 28px;
    font-family: 'product_sansregular';
    margin-bottom: 6px;
}
.what-new-box .what-new-box-content p,.what-new-box-more-content p {
    font-size: 16px;
    line-height: 22px;
    color: #ffffff;
}
.what-new-box .what-new-link {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 9;
    top: 0;
    left: 0;
}
.what-new-box .what-new-box-more-content{
    position: absolute;
    bottom:-100%;
    padding: 16px;
    background-color: #ffffff;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-transition:0.3s all ease-in-out;
    -o-transition:0.3s all ease-in-out;
    transition:0.3s all ease-in-out;
}
.what-new-box-more-content p{
    color: #63666A;
    max-width: 260px;
}
.what-new-box-more-content i {
    width: 32px;
    height: 32px;
    background-color: #C8102E;
    border-radius: 50%;
    color: #ffffff;
    line-height: 32px;
    text-align: center;
    font-size: 25px;
}
.what-new-box:hover .what-new-box-more-content { bottom: 0; }
.lease-tabs-wrap.nav-tabs li .nav-link {
    border: 0;
    color: #63666A;
    font-size: 16px;
    line-height: 20px;
    padding: 8px 31px;
    border-radius: 100px;
    -webkit-transition:0.3s all ease-in-out;
    -o-transition:0.3s all ease-in-out;
    transition:0.3s all ease-in-out;
}
.nav-tabs.lease-tabs-wrap {
    border-bottom: 0;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}
.lease-tabs-wrap.nav-tabs li .nav-link.active,.lease-tabs-wrap.nav-tabs li .nav-link:hover{
    -webkit-box-shadow: 0px 0px 16px #0000000D;
            box-shadow: 0px 0px 16px #0000000D;
    background-color: #ffffff;
    color: #c8102e;
}
ul.nos-vehicle-list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
}
ul.nos-vehicle-list li input {
    width: 0;
    height: 0;
    font-size: 0;
    line-height: 0;
    position:absolute;
}
ul.nos-vehicle-list li label p {
    font-size: 12px;
    line-height: 15px;
    font-weight: bold;
    white-space: nowrap;
    margin-left: 6px;
}
ul.nos-vehicle-list li label {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    margin-bottom: 0;
    padding: 13px 14px;
    background-color:transparent;
    border-radius: 50px;
    cursor: pointer;
    border: 1px solid rgba(0, 0, 0, 0.1);
}
ul.nos-vehicle-list li + li { margin-left: 8px; }
ul.nos-vehicle-list li input:checked + label { border-color: #C8102E;background-color: #ffffff;}
ul.nos-vehicle-list li input:checked + label p {color: #C8102E; }

.range-custom {
    -webkit-appearance: none;
    width: 100%;
    height: 6px;
    background: #DEDEDE;
    outline: none;
    -webkit-transition: .2s;
    -webkit-transition: opacity .2s;
    -o-transition: opacity .2s;
    transition: opacity .2s;
    border-radius: 50px;
}

.range-custom::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 8px;
    height: 8px;
    background: #ffffff;
    cursor: pointer;
    border-radius: 50%;
    border: 8px solid #c8102e;
    -webkit-box-sizing: content-box;
            box-sizing: content-box;
}
.nos-vehicle-list.lease-tenure-radio li label p {
    font-size: 16px;
    line-height: 20px;
    margin-left: 0;
}
.nos-vehicle-list.lease-tenure-radio li label {
    padding:7px 44px;
    border-color: #DEDEDE;
}
.nos-vehicle-list.lease-tenure-radio li input:checked + label,.nos-vehicle-list.lease-tenure-radio li label:hover {
    border-color: #ffffff;
    -webkit-box-shadow: 0px 0px 16px #0000000D;
            box-shadow: 0px 0px 16px #0000000D;
}
ul.nos-vehicle-list.lease-tenure-radio li + li { margin-left: 15px; }
.submit-lease-enquiry {
    font-size: 16px;
    line-height: 16px;
    width: 100%;
    background-color: #C8102E;
    color: #ffffff;
    padding:15px 20px;
    text-align: center;
    border-radius: 80px;
    border: 1px solid #C8102E;
    -webkit-transition:0.3s all ease-in-out;
    -o-transition:0.3s all ease-in-out;
    transition:0.3s all ease-in-out;
}
.submit-lease-enquiry:hover{
    background-color: #ffffff;
    color: #C8102E;
}
label.product-title {
    font-size: 16px;
    line-height: 20px;
    color: #63666A;
    margin-bottom: 10px;
}
.lease-tabs-content { margin-top: 30px; }

.nos-vehicle-slider {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.nos-vehicle-slider label.product-title { margin-bottom: 0; }
.nos-vehicle-slider .range-custom { margin-top: 12px; }
.nos-vehicle-slider p { margin-left: auto; }
.nav-tabs.lease-tabs-wrap li + li { margin-left: 10px; }
.vehicle-range-value {
    font-size: 16px;
    line-height: 20px;
    color: #63666A;
    font-family: 'nimbus_sans_d_otlight';
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    width: 100%;
    margin-top: 10px;
}
.dropdown.country-dropdown button i { display: none; }
.header-top-info {
    background-color: #000000;
    font-size: 14px;
    line-height: 17px;
    color: #999999;
    padding: 11px 0;
}
.header-top-info a { color: #ffffff; }
.header-top-info .close-info { color: #999999; }


/*-- Listing Page --*/
/*-- Listing Page Header --*/
.call-header span,.call-header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.call-header i {
    width: 100%;
    font-style: normal;
    font-size:15px;
    line-height:17px;
    color:#000000;
}
.call-header span b {
    font-size: 15px;
    line-height: 17px;
    color: #C8102E;
    font-weight: normal;
    text-transform:uppercase;
}
.offer-filter-wrap .filter-box.custom-control.custom-switch label.custom-control-label {
    font-size: 16px;
    line-height: 24px;
}
.vehicle-box-detail-wrap > button.close {
    top: 0;
    right: 40px;
    font-size: 20px;
}
/* header.header-nav.header-invert { padding: 26px 0;} 
.header-invert .call-header img { margin-right: 10px; }
.header-invert .login-register-wrap a,.header-invert .login-register-wrap:before {
    color:#C8102E;
    font-weight: normal;
}
.header-invert .login-register-wrap a + a{border-color:#C8102E;}
.header-invert .call-header { margin-left: auto; } */
header.header-nav.header-invert { background-color: #ffffff;padding: 16px 0;} 
.header-invert .call-header img { margin-right: 10px; }
/* .header-invert .dropdown.country-dropdown button { color: #ffffff; }
.header-invert .dropdown.country-dropdown button:before{background-image: url('../images/world-lang-invert.svg');} */
.header-invert .login-register-wrap a,.header-invert .login-register-wrap:before {
    color: #ffffff;
    font-weight: normal;
}
.header-invert .login-register-wrap a + a{border-color:#ffffff;}
.header-invert .call-header { margin-left: auto; }
.toggle-icon span {
    width: 27px;
    height: 3px;
    background: #000000;
    margin:5px auto;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    display: block;
    opacity: 0.3;
    border-radius: 5px;
}
.toggle-icon.cross-toggle span.one {
    -webkit-transform: rotate( 45deg ) translate(6px, 6px);
    -ms-transform: rotate(45deg) translate(6px, 6px);
    transform: rotate( 45deg ) translate(6px, 6px);
}
.toggle-icon.cross-toggle span.two {
    opacity: 0;
}
.toggle-icon.cross-toggle span.three {
    -webkit-transform: rotate( -45deg ) translate(6px, -6px);
    -ms-transform: rotate(-45deg) translate(6px, -6px);
    transform: rotate( -45deg ) translate(6px, -6px);
}
.toggle-icon {
    margin-right: 35px;
}
.header-invert .reguster-right-wrap .country-dropdown {
    margin-right: 50px;
}

.top-left-menu-wrapper {
    z-index:99;
    padding: 10px 20px;
    left: -100%;
    position: fixed;
    background-color: #ffffff;
    width: 100%;
    top:119px;
    -webkit-transition:0.3s all ease-in-out;
    -o-transition:0.3s all ease-in-out;
    transition:0.3s all ease-in-out;
}
.header-sticky .top-left-menu-wrapper{
    top:80px;
}
body.toggle-left-body .top-left-menu-wrapper{ left:0; }
.header-left-menu-section { padding: 10px 0; }

.pickup-dropdown-box-design{
    width: 1480px;
    max-height:438px;
    border-radius: 40px;
    max-width: 100%;
    left: 0;
    right:0;
    margin:0 auto;
    padding: 0;
    overflow: hidden;
    z-index:1;
    background-color:#ffffff;
    position:absolute;
    display:none;
    top:calc(100% + 2px);
}
.toggle-serach-bar .pickup-dropdown-box-design {
    display: block;
}
.pickup-dropdown-box-design .use-my-current-address-wrap-search {
    background-color: rgba(0, 0, 0, 0.15); 
    padding: 10px 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.use-my-current-address-wrap-search img { margin-right: 5px; }
.pickup-dropdown-box-design .recent-search-wrap,.pickup-dropdown-box-design .airport-location-wrap {
    padding: 0 15px;
}
.recent-search-wrap .title-search-box, .airport-location-wrap .title-search-box {
    font-size: 16px;
    line-height: 22px;
    color: #000000;
    font-family: 'product_sansbold';
    text-transform: uppercase;
}
.recent-search-wrap > ul {
    padding: 0;
    margin: 0 -5px;
    list-style: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
}
.recent-search-wrap > ul > li {
    margin-top: 6px;
    margin-left: 5px;
}
.recent-search-wrap > ul .recent-search-pills{
    font-size:14px;
    line-height:20px;
}
.airport-location-wrap > ul .airport-location-btn {
    font-size: 14px;
    line-height: 20px;
    font-weight: normal;
    color: rgba(0, 0, 0, 0.6);
}
.airport-location-wrap > ul > li { padding: 10px 0; }
.airport-location-wrap > ul > li + li { border-top: 1px solid rgba(0, 0, 0, 0.4); }
.airport-location-wrap > ul .airport-location-btn > img {
    width: 25px;
    height: auto;
    border-radius: 8px;
    margin-right: 5px;
}

/*-- Footer invert --*/
footer.footer-invert { padding: 15px 0;margin-top: 50px;}
footer.footer-invert .footer-wrap { display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-align: center; -ms-flex-align: center; align-items: center; -ms-flex-wrap: wrap; flex-wrap: wrap; }
.footer-invert ul.footer-navigation{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 0;
    list-style: none;
    margin: 0;
    margin-left: auto;
}
.footer-invert ul.footer-navigation li + li { margin-left: 50px; }
.footer-invert ul.footer-navigation a { color: #000000; }
.recent-search-wrap > ul .recent-search-pills {
    font-size: 14px;
    line-height: 20px;
    padding: 8px 13px;
    border: 1px solid rgba(0, 0, 0, 0.4);
    border-radius: 12px;
    color:rgba(0, 0, 0, 0.4);
}
.airport-location-wrap ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

ul.rental-steps-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    list-style: none;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 5px 0 5px;
    margin: 0;
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
    position: relative;
}
ul.rental-steps-wrap a {
    font-size: 18px;
    line-height: 22px;
    color: #C8102E;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}
.rental-detail-place span {
    max-width: 100px;
    overflow: hidden;
    -o-text-overflow: ellipsis;
       text-overflow: ellipsis;
    white-space: nowrap;
    display: none;
}

.rental-steps-content { margin-top:5px; }
ul.rental-steps-wrap a:before{
    content: "\f00c";
    font-family: 'FontAwesome';
    width: 22px;
    height: 22px;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius:50%;
    display:inline-block;
    color: #ffffff;
    font-size: 13px;
    line-height: 23px;
    text-align: center;
}
ul.rental-steps-wrap .checked a:before{
    background-color:#C8102E;
    color: #ffffff;
}
ul.rental-steps-wrap a .rental-steps-title { margin-left: 11px;color:#000000;}
ul.rental-steps-wrap li:last-child img { display: none; }
ul.rental-steps-wrap li img { opacity: 0.3; }
ul.rental-steps-wrap li.active.checked img { opacity:1; }
ul.rental-steps-wrap li:last-child span { margin-right: 0; }
ul.rental-steps-wrap li {max-width:228px;position: relative;width:100%;}
ul.rental-steps-wrap li:first-child { margin-left:0; }
ul.rental-steps-wrap li:not(:last-child)::after {
    content: '';
    position: absolute;
    background-image: url('../images/rental-steps.svg');
    background-repeat: no-repeat;
    width: 16px;
    height: 16px;
    right: -37px;
    top: 50%;
    -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
            transform: translateY(-50%);
    background-size: 24px;
    background-position: center;
    opacity:0.3;
}
ul.rental-steps-wrap li.active.checked::after{opacity:1;}
.rental-steps-content.rental-steps-current {
    font-size: 18px;
    line-height: 24px;
    color: rgb(0, 0, 0, 0.5);
    text-align: center;
    display: none;
}
.rental-detail-place {
    font-size: 16px;
    line-height: 24px;
    color: #006FF7;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    position: relative;
    display: none;
}
.rental-detail-place time { display: block; }
.rental-detail-place > div { text-align: center; }
.rental-detail-place .rental-detail-place-right { padding-left: 25px;position: relative;}
.rental-detail-place .rental-detail-place-right::before {
    content: '-';
    position: absolute;
    left: 10px;
    top:50%;
    -webkit-transform:translateY(-50%);
        -ms-transform:translateY(-50%);
            transform:translateY(-50%);
}
.rental-detail-place-two:before {content: '';position: absolute;width: 6px;height: 1px;background-color: #006FF7;top: 50%;-webkit-transform: translateY(-50%);-ms-transform: translateY(-50%);transform: translateY(-50%);}
ul.rental-steps-wrap li.active .rental-steps-title { color: #c8102e; }


.pickup-location-input label {
    display: block;
    margin-bottom: 2px;
    font-size: 15px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.4);
}
input.input-custom {
    border: 1px solid rgba(0, 0, 0, 0.1);
    font-size: 18px;
    line-height: 24px;
    color: #000000;
    font-weight: 600;
    padding: 13px 40px;
    border-radius:10px;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center left 15px;
    height:53px;
}
.important-info-start-stick i,.important-info-start-bg-stick i { margin-right: 4px; }
.important-info-start-stick {
    color: #ed3153;
    font-size: 14px;
    line-height: 19px;
    margin-top: 3px;
    position: relative;
    z-index:1;
}
.important-info-start-bg-stick {
    color: #ffffff;
    font-size: 14px;
    line-height: 19px;
    margin-top: 3px;
    margin-bottom: 3px;
    background-color: #f73458;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 5px 9px;
    border-radius: 8px;
    position: relative;
    z-index:1;
}
.important-info-start-bg-apitype {
    color: #ffffff;
    font-size: 14px;
    line-height: 19px;
    margin-top: 3px;
    margin-bottom: 3px;
    background-color: #603c96;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 5px 9px;
    border-radius: 8px;
    position: relative;
    z-index:1;
}
.charges-option-per-day p.del-price {
    color: #000000;
    margin-top: 5px;
    font-size: 80%;
}
.charges-option-per-day p.del-price span { color: #000000; }

input.input-custom::-webkit-input-placeholder{
    color:rgba(0, 0, 0, 0.3);
}
input.input-custom::-moz-placeholder{
    color:rgba(0, 0, 0, 0.3);
}
input.input-custom:-ms-input-placeholder{
    color:rgba(0, 0, 0, 0.3);
}
input.input-custom::-ms-input-placeholder{
    color:rgba(0, 0, 0, 0.3);
}
input.input-custom::placeholder{
    color:rgba(0, 0, 0, 0.3);
}
.promocode-offer-input input::-webkit-input-placeholder{
    color:#C40F2D;
}
.promocode-offer-input input::-moz-placeholder{
    color:#C40F2D;
}
.promocode-offer-input input:-ms-input-placeholder{
    color:#C40F2D;
}
.promocode-offer-input input::-ms-input-placeholder{
    color:#C40F2D;
}
.promocode-offer-input input,.promocode-offer-input input::placeholder{
    color:#C40F2D;
}
.promocode-offer-input input { padding: 15px 20px; }
.pickup-location-box-wrap {
    -webkit-box-shadow: 0px 0px 32px #0000000D;
    box-shadow: 0px 0px 32px #0000000D;
    background-color: #ffffff;
    border-radius: 10px;
    padding: 24px 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin: 26px 0 29px;
}
input.input-custom:focus{outline:0;}
.pickup-location-offer {
    background-color: #C8102E;
    font-size: 18px;
    line-height: 24px;
    color: #ffffff;
    border: 0;
    padding: 14px 55px;
    border-radius: 10px;
}
.have-promocode-link,.have-promocode-link:hover {
    display: block;
    font-size: 18px;
    line-height: 22px;
    color: #C8102E;
    margin-top: 14px;
    text-align: center;
    font-weight: bold;
}
.group-input {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
}
.group-input input.input-custom + input.input-custom {
    border-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    width: 50%;
}
.group-input input:not(:last-child) {border-right: 0;border-top-right-radius: 0;border-bottom-right-radius: 0;width: 50%;}
.group-input label { width: 100%; }
input.input-custom.input-location{
    background-image: url('../images/location-icon.svg');
}
input.input-custom.input-date{
    background-image: url('../images/date-icon.svg');
    background-size: 18px auto;
}
input.input-custom.input-time{
    background-image: url('../images/time-icon.svg');
    background-size: 18px auto;
}
.pickup-location-input{max-width:100%;}
.pickup-location-box-wrap .pickup-location-input { width: 310px;}
.pickup-location-box-wrap .pickup-location-input.group-input { width:260px; }
.promocode-offer-input { max-width: 185px; }
.group-input input.input-custom { padding-right: 5px; }
.pickup-location-box-wrap .show-offer-btn-wrap { width: 160px;-ms-flex-item-align: end;align-self: flex-end;}
.pickup-location-box-wrap .show-offer-btn-wrap .pickup-location-offer { padding: 14px 35px; }

.offer-filter-wrap h3 {
    font-size: 24px;
    line-height: 24px;
    color: #603c96;
    margin-right: 72px;
}
.offer-filter-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
}
.filter-box {
    border: 1px solid #0000001A;
    font-size: 16px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.5);
    padding: 8px 18px;
    border-radius:10px;
    -webkit-appearance:none;
       -moz-appearance:none;
            appearance:none;
}
.filter-box:focus{outline:0;}
.offer-filter-wrap .filter-box { width: 100%; max-width: 170px; }
select.filter-box {
    background-image:url('../images/down-arrow-select.svg');
    background-repeat: no-repeat;
    background-size: 10px;
    background-position: right 17px center;
}
.custom-switch .custom-control-label::before {
    width: 42px;
    height: 24px;
    border-radius: 50px;
}
.custom-switch .custom-control-label::after {
    width: 20px;
    height: 20px;
    border-radius: 50%;
}
.custom-switch .custom-control-input:checked~.custom-control-label::after {
    -webkit-transform: translateX(18px);
    -ms-transform: translateX(18px);
        transform: translateX(18px);
}
.filter-box.custom-control.custom-switch .custom-control-label::before { 
    left: auto;
    right: -60px;
    top: 0;
}
.custom-switch .custom-control-label::before{
    background-color: rgba(0, 0, 0, 0.2);
    border:0;
}
.custom-control-input:checked~.custom-control-label::before{
    border-color: #c8102e;
    background-color: #c8102e;
}
.filter-box.custom-control.custom-switch .custom-control-label::after {
    left: auto;
    right: -40px;
    top: 2px;
    background-color:#ffffff;
}
.offer-filter-wrap .filter-box + .filter-box { margin-left: 10px; }

.vehicle-box-design {
    max-width: 370px;
    -webkit-box-shadow:5px 5px 20px rgba(0, 0, 0, 0.1);
    box-shadow:5px 5px 20px rgba(0, 0, 0, 0.1);
    padding: 25px;
    border-radius: 10px;
    background-color:#ffffff;
    margin: 0 13px;
    margin-top:40px;
    position: relative;
    -webkit-box-ordinal-group:1;
    -ms-flex-order:0;
    order:0;
    width:100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    overflow: hidden;
    flex-direction: column;
    align-items: flex-start;
}
.vehicle-box-design img.vehicle-image-main{width: 100%;height: auto;-webkit-transition:0.3s all ease-in-out;-o-transition:0.3s all ease-in-out;transition:0.3s all ease-in-out;transform:scale(0.90);margin-top: auto;}
.vehicle-box-design.selected:after {
    content: '';
    position: absolute;
    bottom:-3px;
    left: 0;
    right: 0;
    width: 81%;
    height: 3px;
    background-color: #C8102E;
    margin: 0 auto;
}
.vehicle-box-design-book {
    font-size: 18px;
    line-height: 24px;
    color: #C8102E;
    font-weight: bold;
    border: 2px solid #C8102E;
    padding: 12px 41px;
    border-radius: 12px;
    -webkit-transition:0.3s all ease-in-out;
    -o-transition:0.3s all ease-in-out;
    transition:0.3s all ease-in-out;
}
.vehicle-box-design-book:hover {
    background-color: #C8102E;
    color: #ffffff;
}
.vehicle-box-design:hover .vehicle-image-main{
    -webkit-transform:scale(1);
        -ms-transform:scale(1);
            transform:scale(1);
}

.vehicle-box-design.selected:before {
    content: '';
    width: 24px;
    background-color: transparent;
    position: absolute;
    bottom: -15px;
    left: 0;
    right: 0;
    margin: 0 auto;
    border-top: solid 12px rgb(200,30,50);
    border-left: solid 12px transparent;
    border-right: solid 12px transparent;
}
.vehicle-box-design h3 {
    font-size: 20px;
    line-height: 20px;
    position: relative;
    z-index:1;
}
.vehicle-box-design .similar-text {
    font-size: 14px;
    line-height: 19px;
    color:rgba(9, 9, 9, 0.5);
    width:100%;
    position: relative;
    z-index:1;
    margin-top: 3px;
}
.vehicle-box-design .charges-option-up {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    width:100%;
    margin-bottom: 8px;
    position: relative;
    z-index:1;
    margin-top: auto;
}
.charges-option-up p {
    font-size: 14px;
    line-height: 20px;
    color: rgb(0, 0, 0, 0.4);
}
.charges-option-up p span {
    font-size: 18px;
    line-height: 20px;
    color: #C8102E;
}
.vehicle-box-design .charges-option-up a {
    font-size: 14px;
    line-height: 16px;
    color: rgb(0, 0, 0, 0.8);
    font-weight: bold;
}
.feature-vehicle-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack:center;
        -ms-flex-pack:center;
            /* justify-content:center; */
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    padding-top: 17px;
    border-top: 1px solid #F0F0F0;
    width:100%;
}
.offer-box-wrapper {
    border-top: 1px solid #EFEFEF;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    margin:20px -10px 50px;
}
.feature-vehicle-box .feature-icon img { margin-right: 6px; }
.feature-vehicle-box .feature-icon + .feature-icon {
    margin-left: 14px;
    padding-left: 14px;
    border-left: 2px solid #F0F0F0;
}
.feature-vehicle-box .feature-icon span {
    font-size: 15px;
    line-height: 18px;
    color: rgba(0, 0, 0, 50%);
}
.vehicle-box-detail-wrap {
    position: relative;
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    width: 100%;
    background-color: #FFFFFF;
    -webkit-box-shadow: 0px 0px 16px #0000000d;
            box-shadow: 0px 0px 16px #0000000d;
    border-radius: 10px;
    max-width: 1560px;
    margin: 40px auto 0;
    padding:40px 20px;
}
.vehicle-box-detail-wrap button.close {
    position: absolute;
    right: 20px;
    top: 0;
    width: 40px;
    height: 40px;
    font-size: 16px;
    line-height: 19px;
    z-index: 99;
    opacity:1;
}
.vehicle-box-detail-wrap button.close:not(:disabled):not(.disabled):hover{opacity:1;}
.vehicle-box-detail-wrap button.close li .book-now-all { color: #000000; }
.vehicle-box-detail-wrap button.close > ul {
    padding: 0;
    margin: 0;
    list-style: none;
    width: 160px;
    height: auto;
    -webkit-box-shadow: 0px 0px 16px #00000026;
            box-shadow: 0px 0px 16px #00000026;
    background-color: #ffffff;
    position: absolute;
    right: 0;
    z-index: 99;
    text-align: left;
    border-radius: 10px;
    top: calc(100% + 10px);
    -webkit-transform: translateY(10px);
        -ms-transform: translateY(10px);
            transform: translateY(10px);
    visibility: hidden;
    opacity: 0;
    -webkit-transition:0.3s all ease-in-out;
    -o-transition:0.3s all ease-in-out;
    transition:0.3s all ease-in-out;
}
footer.footer-invert .footer-logo { margin-bottom: 0; }
.vehicle-box-detail-wrap button.close:hover > ul{
    -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
            transform: translateY(0);
    visibility: visible;
    opacity:1;
}
.vehicle-box-detail-wrap button.close > ul li {
    padding: 14px;
    border-bottom: 1px solid #E0E0E0;
}
.vehicle-box-detail-wrap button.close > ul::before {content: '';position: absolute;border-left: 10px solid transparent;border-right: 10px solid transparent;border-bottom: 10px solid white;top: -10px;right: 10px;}
.vehicle-box-detail-wrap button.close > ul li:last-child {
    border-bottom: 0;
}
.vehicle-box-detail-wrap button.close li .book-now-all i,.vehicle-box-detail-wrap button.close li .book-now-all img{margin-right: 10px;}
.vehicle-box-detail-wrap button.close li .book-now-all.book-now-email i { color: #c8102e; }
.vehicle-box-detail-wrap button.close li .book-now-all.book-now-phone i { color: #006FF7; }
.box-title-icon-border { position: relative; padding-bottom: 10px; }
.box-title-icon-border::after {content: '';position: absolute;left: 0;bottom: 0;background-color: #C8102E;height: 2px;width:300px;}
.vehicle-box-detail-wrap .vehicle-box-detail-left {-ms-flex: 0 0 60%;-webkit-box-flex: 0;flex: 0 0 60%;max-width: 60%;}
.vehicle-box-detail-wrap > div {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}
img {
    max-width: 100%;
    height: auto;
}
.vehicle-box-detail-wrap .vehicle-box-detail-right {
    -ms-flex: 0 0 40%;
    -webkit-box-flex: 0;
            flex: 0 0 40%;
    max-width: 40%;
}
.vehicle-detail-slider-wrap .slide-arrow {
    top: 50%;
    -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
            transform: translateY(-50%);
    position: absolute;
    z-index: 9;
    background-color: #000000;
    width: 40px;
    height: 40px;
    padding: 0;
    text-align: center;
    line-height: 40px;
    color: #ffffff;
    font-size: 20px;
    border-radius: 50%;
    opacity: 0.2;
}
.vehicle-detail-slider-wrap .slide-arrow.prev-arrow {
    left: 0;
    right: auto;
}
.vehicle-detail-slider-wrap .slide-arrow.next-arrow {
    left:auto;
    right:0;
}
.vehicle-detail-slider img { margin: 0 auto; }


.featured-rent-price-radio {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin: 28px 0 28px;
    padding-top: 22px;
    border-top: 1px solid #F0F0F0;
}

.featured-rent-radio label {
    margin-bottom: 0;
    position: relative;
    padding-left: 35px;
}
.featured-rent-radio label p {
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    opacity: 0.4;
}
.featured-rent-radio label span {
    font-size: 20px;
    line-height: 38px;
    color: #000000;
    font-weight: bold;
}
.featured-rent-radio input + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
            transform: translateY(-50%);
    width: 20px;
    height: 20px;
    border: 1px solid #C40F2D;
    border-radius: 100%;
    background: #ffffff;
}
.featured-rent-radio input {
    position: absolute;
    left: -9999px;
}
.featured-rent-radio input + label:after {
    content: '';
    position: absolute;
    left: 4px;
    top: 50%;
    -webkit-transform: translateY(-50%) scale(0);
        -ms-transform: translateY(-50%) scale(0);
            transform: translateY(-50%) scale(0);
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #C40F2D;
    -webkit-transform: scale(0);
        -ms-transform: scale(0);
            transform: scale(0);
}
.featured-rent-radio input:checked + label:after{ -webkit-transform: translateY(-50%) scale(1); -ms-transform: translateY(-50%) scale(1); transform: translateY(-50%) scale(1); }
.featured-rent-radio label span b { color: #C40F2D; }
.featured-rent-price-radio .featured-rent-radio + .featured-rent-radio { margin-left: 60px; }
.book-now-all-bttns-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
}
.book-now-all-bttns-wrap .book-now-all {
    width: 52px;
    height: 52px;
    border: 2px solid #cccccc;
    border-radius: 15px;
    text-align: center;
    line-height: 48px;
    font-size:20px;
}
.book-now-all.book-now-email { color: #c8102e; }
.book-now-all-bttns-wrap > a { margin-left: 10px; }
.book-now-all-bttns-wrap .book-now-btttn { max-width: 100%;margin-left: 0;}
.book-now-btttn,.book-now-btttn:hover {
    background-color: #C8102E;
    color: #ffffff;
    font-size: 18px;
    line-height: 24px;
    width: 100%;
    padding: 14px 16px;
    border-radius: 10px;
    text-align: center;
}
.included-wrapper-featured h5 {
    font-size: 16px;
    line-height: 19px;
    color: #C8102E;
    font-weight: 600;
    margin-bottom: 14px;
}
ul.check-box-listing {
    margin: 0;
    padding: 0;
    list-style: none;
}
ul.check-box-listing li {
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    opacity: 0.7;
    position: relative;
    margin-top: 8px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
ul.check-box-listing li:before {
    /* content: url("../images/checked-list.svg"); */
    width: 13px;
    display: inline-block;
    margin-right: 13px;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
}
ul.check-box-listing1 li:before {
   
    width: 13px;
    display: inline-block;
    margin-right: 13px;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
}
ul.check-box-listing li span {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 65%;
            flex: 0 0 65%;
    letter-spacing: -0.3px;
}
ul.check-box-listing li a {
    -ms-flex-item-align: start;
        align-self: flex-start;
    margin-left: auto;
}
.included-wrapper-featured { padding-top: 26px; margin-top: 26px; border-top: 1px solid #F0F0F0; }
.vehicle-box-detail-right-content .feature-vehicle-box {
    margin-top: 14px;
    border-top: 0;
    padding-top: 0;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
}
.vehicle-box-detail-right-content .similar-text {
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    opacity: 0.5;
}

ul.navbar-nav > li.nav-item.has-submenu > .submenu-list-wrapper {
    position: absolute;
    left: 0;
    right:0;
    width: 100%;
    background-color: #ffffff;
    -webkit-transform: translateY(20%);
        -ms-transform: translateY(20%);
            transform: translateY(20%);
    opacity:0;
    visibility:hidden;
    -webkit-transition:0.3s all ease-in-out;
    -o-transition:0.3s all ease-in-out;
    transition:0.3s all ease-in-out;
    padding:15px 25px;
}
.submenu-wrap-box img {
    width: 100%;
    height: auto;
}
.submenu-wrap-box .title-nav {
    font-size: 20px;
    line-height: 26px;
    padding: 10px;
    font-family: 'product_sansbold';
}
ul.navbar-nav > li.nav-item.has-submenu.menu-toggle > .submenu-list-wrapper {
    visibility: visible;
    opacity: 1;
    -webkit-transform: translateY(0);
        -ms-transform: translateY(0);
            transform: translateY(0);
    -webkit-box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
            box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
}
ul.submenu-list-wrap {
    padding: 0;
    list-style: none;
}
header.header-nav ul.submenu-list-wrap li + li { margin-left: 0; }
ul.submenu-list-wrap > li > a {
    width: 100%;
    padding: 10px 20px;
    color: #000000;
    font-size: 16px;
    line-height: 20px;
    position: relative;
}
.submenu-wrap-box > ul.submenu-list-wrap .submenu-links:before {
    content: "\f105";
    font-family: 'FontAwesome';
    position: absolute;
    left: 0;
    top:9px;
}
.header-left-menu-section .row > div:not(:first-child) {
    border-left: 1px solid #dee2e6;
}
body.toggle-left-body{overflow:hidden;}

.upgrade-option-modal-content {
    padding: 20px 35px;
    border-radius: 20px;
    overflow:hidden;
}
.upgrade-option-modal-content .slick-list { overflow: visible; }
.upgrade-option-modal-content h2 {
    font-size: 26px;
    line-height: 26px;
}
.upgrade-option-modal-content .offer-box-wrapper {
    border-top: 0;
    padding-top: 0;
    margin-top: 0 !important;
}
.upgrade-option-modal-content .offer-box-wrapper .slick-list { width: 100%; }
.upgrade-option-modal-content .offer-box-wrapper .vehicle-box-design .feature-vehicle-box {
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}

.upgrade-option-modal-content .offer-box-wrapper .vehicle-box-design .feature-vehicle-box .feature-icon + .feature-icon {
    margin-left: 5px;
    padding-left: 5px;
}
.vehicle-design-upgrade-box {
    padding: 25px;
    -webkit-box-shadow: 0px 0px 16px rgb(0 0 0 / 10%);
    box-shadow: 0px 0px 16px rgb(0 0 0 / 10%);
    background-color: #ffffff;
    border-radius: 0;
}
.vehicle-design-upgrade-box .vehicle-box-design {
    background-color: transparent;
    -webkit-box-shadow: none;
            box-shadow: none;
    padding: 0;
    margin: 0;
}
.upgrade-slider-box-wrap {
    max-width: 30.94%;
    margin: 0 13px;
}
.book-vehicle-name-btn {
    font-size: 18px;
    line-height: 24px;
    background-color: #C8102E;
    color: #ffffff;
    width: 100%;
    max-width: 340px;
    border-radius: 10px;
    padding: 12px 10px;
    border: 2px solid #C8102E;
    -webkit-transition:0.3s all ease-in-out;
    -o-transition:0.3s all ease-in-out;
    transition:0.3s all ease-in-out;
}
.book-vehicle-name-btn:hover{
    background-color:#ffffff;
    color:#C8102E;
}
.feature-icon { display: -webkit-box; display: -ms-flexbox; display: flex; }
.upgrade-option-modal-content .offer-box-wrapper .slide-arrow {
    font-size: 26px;
    background-color: #ffffff;
    width: 35px;
    height: 35px;
    -webkit-box-shadow: 0px 3px 16px #00000029;
            box-shadow: 0px 3px 16px #00000029;
    line-height: 35px;
    text-align: center;
    border-radius: 50%;
    color: #C8102E;
    -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
            transform: translateY(-50%);
    position: absolute;
    top: 50%;
    left: 0;
    z-index: 9;
}
.upgrade-option-modal-content .offer-box-wrapper .slide-arrow.next-arrow {
    right: 0;
    left: auto;
}
header.header-nav.header-invert .top-left-menu-wrapper { top: 92px; }
.rental-steps-section { margin-bottom: 20px;background-color: #EEEEEE;}
p.vehicle-featuress-content {
    font-size: 16px;
    line-height: 19px;
    color: #C8102E;
}

p.vehicle-featuress-content span {
    display: block;
    color: #000000;
    opacity: 0.7;
    margin-top: 5px;
}

.box-shadow-white-box {
    -webkit-box-shadow: 0px 0px 32px #0000000d;
    box-shadow: 0px 0px 32px #0000000d;
    background-color: #ffffff;
    border-radius: 10px;
    padding:22px 50px;
    margin-bottom: 20px;
}

.pickup-location-flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
}
.pickup-location-flex-content h3 {
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 5px;
}
.pickup-location-flex-content address {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 0;
    color: #000000;
    opacity: 0.4;
}
.pickup-location-flex-img{margin-right:20px;}

ul.feature-list {
    margin: 0;
    padding: 0;
    list-style: none;
}
ul.feature-list li {
    margin-bottom: 15px;
}
ul.included-wrapper-featured-pricing {
    padding: 0;
    margin: 0;
    list-style: none;
    margin-top: 0;
}
.baby-seat-select-box {
    border: 1px solid #827F7F;
    border-radius: 10px;
    font-size: 18px;
    line-height: 24px;
    padding: 16px 16px;
}
.view-details-wrap h4 {
    font-size: 28px;
    line-height: 1;
}

.view-details-wrap p {
    font-size: 18px;
    line-height: 1.6;
}
.faq-wrapper .panel-heading .accordion-toggle.collapsed:after {
    content: "\f107";
}
.faq-wrapper .panel-heading .accordion-toggle:after {
    content: "\f106";
}
.faq-wrapper .panel + .panel { margin-top: 40px; }
.faq-wrapper h4.panel-title { font-family: 'product_sansregular'; }

.box-title-icon-border .box-title-icon-rounded {
    width: 30px;
    height: 30px;
    background-color: rgb(200 16 46 / 10%);
    text-align: center;
    line-height: 30px;
    border-radius: 7px;
    margin-right: 10px;
}
.view-details-box-wrap.custom-switch .custom-control-label::before,
.view-details-box-wrap.custom-switch .custom-control-label::after,
.extra-box-range-wrap.custom-switch.custom-control label.custom-control-label::before,
.extra-box-range-wrap.custom-switch.custom-control label.custom-control-label::after{
    left:auto;
    right:0;
    top:50%;
    -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
            transform: translateY(-50%);
}
.view-details-box-wrap.custom-switch .custom-control-label::after,.extra-box-range-wrap.custom-switch.custom-control label.custom-control-label::after { right: 20px;background-color: #ffffff;}
.view-details-box-wrap.custom-switch .custom-control-input:checked~.custom-control-label::after,.extra-box-range-wrap.custom-switch.custom-control .custom-control-input:checked~label.custom-control-label::after {
    -webkit-transform: translate(18px, -50%);
    -ms-transform: translate(18px, -50%);
    transform: translate(18px, -50%);
}

.extra-box-range-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    border: 1px solid rgb(0 0 0 / 5%);
    border-radius: 10px;
    padding: 10px;
}
.extra-box-range-box {
    width: 70px;
    height: 70px;
    background-color:#F4F4F4;
    border-radius: 7px;
    text-align: center;
    line-height: 70px;
}
.box-title-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}
.extra-box-range-box img { -webkit-filter: brightness(0); filter: brightness(0); }
.input-quantity button {
    border: 0;
    background-color: rgb(200 16 46 / 10%);
    width: 36px;
    height: 36px;
    color: #C8102E;
    font-size: 22px;
    padding: 0;
    border-radius: 5px;
}
.input-quantity input {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    border: 0;
    font-size: 24px;
    line-height: 29px;
    font-family: 'product_sansbold';
    text-align: center;
    width: 60px;
}
.input-quantity input:focus{outline:0;}
.input-quantity input[type=number]::-webkit-inner-spin-button, 
.input-quantity input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}
.input-quantity button#add { background-color: #C8102E; color: #ffffff; }
.grand-total-wrap {
    padding: 22px 50px;
    border-radius: 10px;
    margin-bottom: 46px;
}
.continue-bttn,.continue-bttn:hover {
    border: 2px solid #c8102e;
    color:#000000;
    padding: 12px 72px;
    border-radius: 10px;
    font-size: 18px;
    line-height: 24px;
    background-color:#ffffff;
}
.img-details-wrap img {
    width: 100%;
    height: auto;
}
ul.booking-details-listing {
    padding: 0;
    margin: 0;
    list-style: none;
}
ul.booking-details-listing .booking-details-listing-left {
    max-width: 65%;
}
ul.booking-details-listing li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}
.booking-details-listing-right{
    font-size:16px;
    line-height:19px;
    color:rgba(0, 0, 0, 0.4);
}
.booking-details-listing-right > span {
    font-size: 24px;
    line-height: 29px;
    color: #C70F2D;
    margin-left: 10px;
}
.booking-details-listing-right > span.h4 {
    font-size: 32px;
    line-height: 40px;
    color: #C70F2D;
}

.custom-input-type-wrap label {
    margin-bottom: 6px;
    font-size: 15px;
    line-height: 24px;
    color: rgb(0 0 0 / 50%);
}
.custom-input-type-wrap input,.custom-input-select-wrap select{
    width: 100%;
    border: 1px solid rgb(0 0 0 / 20%);
    font-size: 18px;
    line-height: 24px;
    color:#000000;
    padding: 13px 16px;
    border-radius: 10px;
}
.custom-input-type-wrap input::-webkit-input-placeholder{
    font-size: 18px;
    line-height: 24px;
    color: rgb(0 0 0 / 20%);
}
.custom-input-type-wrap input::-moz-placeholder{
    font-size: 18px;
    line-height: 24px;
    color: rgb(0 0 0 / 20%);
}
.custom-input-type-wrap input:-ms-input-placeholder{
    font-size: 18px;
    line-height: 24px;
    color: rgb(0 0 0 / 20%);
}
.custom-input-type-wrap input::-ms-input-placeholder{
    font-size: 18px;
    line-height: 24px;
    color: rgb(0 0 0 / 20%);
}
.custom-input-type-wrap input::placeholder,.custom-input-select-wrap select{
    font-size: 18px;
    line-height: 24px;
    color: rgb(0 0 0 / 20%);
}
.custom-input-type-wrap input:focus, .custom-input-select-wrap select:focus {
    border-color: #006FF7;
    -webkit-box-shadow: 0px 0px 16px #006ff740;
            box-shadow: 0px 0px 16px #006ff740;
}
input:focus,select:focus{outline:0;}
.custom-input-select-wrap select {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    background-image: url('../images/select-down-arrow.svg');
    background-repeat: no-repeat;
    background-size: 14px;
    background-position: right 20px center;
}
.box-shadow-grey-box {
    background-color: #F2F2F2;
    border-radius: 10px;
    padding: 22px 50px;
}
.custom-input-type-file-wrap label {
    margin-bottom: 0;
    font-size: 18px;
    line-height: 24px;
    color: #000000;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    cursor: pointer;
}
.input-type-file-image-view {
    width: 200px;
    height: 140px;
    background-color: rgb(245 245 245 / 50%);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    border-radius: 10px;
    margin-left: 95px;
    margin-right: 20px;
}
.custom-input-type-file-wrap input{display:none;}
.custom-input-type-file-wrap span {
    font-size: 15px;
    line-height: 24px;
    opacity: 0.5;
    padding: 14px 101px;
    border: 1px dashed #000000;
    margin-left: 80px;
    border-radius: 10px;
}
ul.rental-policies-list {
    padding: 0;
    margin: 0;
    list-style: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
}
ul.rental-policies-list li {
    margin-right: 10px;
    padding-right: 10px;
    border-right: 1px solid rgb(0 0 0 / 50%);
    margin-top: 10px;
}
ul.rental-policies-list li:last-child{border-right:0;}
.rounded-bttn {
    font-size: 18px;
    line-height: 24px;
    color: #1a79c7;
    border: 2px solid #1a79c7;
    padding: 13px 43px;
    border-radius: 10px;
    -webkit-transition:0.3s all ease-in-out;
    -o-transition:0.3s all ease-in-out;
    transition:0.3s all ease-in-out;
    background: #fff;
}
.rounded-bttn:hover{
    background-color:#1a79c7;
    color:#ffffff;
}
.rounded-bttn-transparent {
    font-size: 18px;
    line-height: 24px;
    color: #ffffff;
    border: 2px solid #C8102E;
    background-color:#c8102e;
    padding: 13px 43px;
    border-radius: 10px;
}
.rounded-bttn-transparent:hover{
    background-color:transparent;
    color:#c8102e;
}
/*my css*/
.box-shadow-white-box-a {
    -webkit-box-shadow: 0px 0px 32px #0000000d;
    box-shadow: 0px 0px 32px #0000000d;
    background-color: #ffffff;
    border-radius: 10px;
    /*padding:22px 50px;*/
    margin-bottom: 20px;
      margin-top: 20px;
}

.pickup-location-box .pickup-sm-title {
    font-size: 14px;
    line-height: 17px;
    color: #C8102E;
    font-weight: bold;
    margin-bottom: 10px;
}

#partner h5{
    font-size: 32px;
        line-height: 36px;
}
#partner h3{
    font-size: 20px;
    line-height: 32px;
}
#partner span{
    font-size: 16px;
    line-height: 28px;
    color: #827F7F;
}
#partner .pickup-location-box{
    padding: 30px 37px;
}
#partner-a .nav-link.active {
    background: #C8102E;
    color: #fff;
    font-size: 14px;
    /*min-width: 221px;*/
    border-radius: 6px;
    text-align: center;
}
#partner-a .nav-link {
    background: #FFFFFF;
    color: #827F7F;
    font-size: 14px;
    min-width: 221px;
    border-radius: 6px;
    border: 1px solid #827F7F4D;
    text-align: center;
}
#partner-a  .card{
    -webkit-box-shadow: 0px 2px 4px #827F7F4D;
            box-shadow: 0px 2px 4px #827F7F4D;
border-radius: 6px;
opacity: 1;
height: 206px;
border: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}
#partner-a .img-details-wrap img {
    width: 196px;
    height: auto;
    text-align: center;
    display: block;
    margin: 0 auto;
}
#partner-a h5{
    font-size: 22px;
    color: #000;

}
.d-flex-center{
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;

}
.phonea {
    width: 17px;
    height: auto;
}

#partner-a p{
    color: #827F7F;
    font-size:14px;
}
.linka,.linka:hover{
    color: #C8102E;
    font-size: 18px;
    margin-top: 10px;
}
/*mywork css*/
.arrow {
  border: solid #C8102E;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}

.right {
  -ms-transform: rotate(-45deg);
      transform: rotate(-45deg);
  -webkit-transform: rotate(-0deg);
}


#reserve .rental-steps-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    list-style: none;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    padding: 20px 0 15px;
    margin: 0;
}
#reserve .rental-steps-wrap a {
    font-size: 20px;
    line-height: 24px;
    color: #C8102E;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-bottom: 15px;
}
#reserve .rental-steps-wrap a:before{
    content: attr(data-steps);
    font-family: 'product_sansregular';
    width:39px;
    height:39px;
    background-color: rgba(0, 0, 0, 0.05);
    border-radius:50%;
    display:inline-block;
    color: #000000;
    font-size: 20px;
    line-height: 41px;
    text-align: center;
      background-color: #C8102E;
    color: #ffffff;
    content: "\f00c";
    font-family: FontAwesome;
}
#reserve {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-left: 20px;
}
.ml-0px {
 
    margin-left: 0px !important;
}
#reserve  .pickup-location-box{
    padding: 20px 15px;
}
.color-red{
    color: #C8102E;
}
.Policy-16{
    font-size: 16px;
}
#labelcust .custom-input-type-wrap{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
#labelcust label{
    font-size: 16px;
    color: #000;
    margin-right: 15px;
    font-weight: 600;
}
#labelcust p{
 font-size: 16px;
 color: #827F7F;
}
.rounded-bttn:hover > svg path.a {
    fill: #fff !important;
    background-color: #000
}
#include ul.check-box-listing li span {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 78%;
            flex: 0 0 78%;
}

#i1 h5{
    text-align: left;
}
#i1 img{
    margin-left: 0px;
}
#i1 p{
    color: #C8102E;
    font-size: 16px;
}
#i1 h6{
    text-align: left;
    color: #827F7F;
    margin-top: 25px;
}

#i1 input{
    height: 52px;
    width: 100%;
}
#partner-ab .nav-link.active {
    background: #C8102E;
    color: #fff;
    font-size: 14px;
    /* min-width: 221px; */
    border-radius: 6px;
    text-align: center;
}
#partner-ab .nav-link {
    background: #FFFFFF;
    color: #827F7F;
    font-size: 14px;
    min-width: 167px;
    border-radius: 6px;
    border: 1px solid #827F7F4D;
    text-align: center;
}

#id1 h5{
    font-size: 40px;
    margin-top: 38px;
    margin-left: 58px;
    margin-bottom: 307px;
}
#id1 h3{
    font-size: 28px;
    margin-top: 23px;
    margin-left: 58px;
}
#id1 span{
    font-size: 20px;
    margin-top: 15px;
    margin-left: 58px;
}
#id2{
    
    color: #FFFFFF;
}
.bg-red{
    background-color: #C8102E;
}
#id2 h5{
   
    margin-top: 36px;
    font-size: 32px;
    line-height: 36px;
}
.pad-30{
    padding: 30px;
}
#id2 img{
    margin-top: 74px;
    height: 64px;
    width: 80px;
}
#id2 h6{
    font-size: 22px;
    margin-top: 44px;
}
#id2 span{
    font-size: 16px;
    margin-bottom: 20px;
    margin-top: 28px;
}
#id3 h5{
    font-size: 36px;
    margin-top: 36px;
    margin-left: 58px;
    line-height: 36px;
}
#id3 span{
    font-size: 16px;
    margin-top: 43px;
    margin-left: 58px;
}
#id3 button{
    margin-left: 57px;
    margin-top: 42px;
}
#id3 img{
    height:599px; 
    width:797px;
}
#id4 h5{
    margin-top: 91px;
}
#id4 li {
    margin-top: 28px;
    margin-right: 115px;
    color: #827F7F;
}
#id4 ul li:before{
    content: "•";
   font-size: 170%;
   padding-right: 5px;
}
#id4 li{
    margin-top: 28px;
    margin-right: 115px;
}
/*#id5 .nav-link.active{
    color:#FFFFFF;
    background-color: #C8102E;
    margin-left: 37px;
    margin-top: 34px;
}
#id5 .nav-link{
    background-color: #FFFFFF;
    color: black;
    border-color: #827F7F4D;
    border-width: 1px;
    margin-top: 34px;
}*/
#id6 h5{
    font-size: 36px;
    margin-top: 0px;
}
#id6 h6{
    margin-top: 41px;
    font-size: 18px;
}
#id6 img{
    margin-top: 10px;
    width: 100%;
    /* margin-left: 36px; */
}
#id6 h3{
    font-size: 28px;
     margin-top: 38px; 
    /* margin-left: 36px; */
}
#id6 span{
    margin-top: 22px;
    font-size: 16px;
    /* margin-left: 36px; */
    /* margin-bottom: 64px; */
}
.btn-outline-success{
   border: 1px solid #0000004D;
border-radius: 20px;
padding: 5px 11px;
margin-right: 10px;
font-size: 14px;
color: #000;
margin-bottom: 5px;
}
#buttoncss img{
    margin-right: 5px;
}
.btn-outline-success:hover {
    color: #c8102e;
    background-color: #fff;
    border-color: #c8102e;
    border-color: #c8102e;
}
.btn-outline-success:hover >svg path.a{
    stroke: #c8102e !important;
      fill: #c8102e !important;
}
.btn-outline-success:hover >svg path{
  
      fill: #c8102e !important;
}
.btn-outline-success svg{
 padding-right: 5px;   
}
#buttoncss.min-129 button{
    min-width: 129px;
}
#buttoncss.min-129 button:hover{
     color: #c8102e;
    background-color: #fff;
    border-color: #c8102e;
    border-color: #c8102e;
}
.bg-grey{
    background-color: #F8F8F8;
}
.rang-slide{
        width: 100%;
}
/* range-slider */

.range-slider {
    margin: 20px 0 0 0%;
}

.range-slider {
    width: 100%;
}

.range-slider__range {
    -webkit-appearance: none;
    width: calc(100% - 1.5rem);
    height: 7px;
    border-radius: 5px;
    background: #f2f2ff;
    outline: none;
    padding: 0;
    position: relative;
    margin: 0;
}
.spanrange {
    -webkit-appearance: none;
    width: 45%;
    height: 7px;
    border-radius: 5px;
    background: #C8102E;
    outline: none;
    padding: 0;
    position: absolute;
    margin: 0;
    left: 15px;
    top: 56px;
}
.range-slider__range::-webkit-slider-thumb {
    -webkit-appearance: none;
            appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
   background: #FFFFFF 0% 0% no-repeat padding-box;
border: 7px solid #C8102E;
    cursor: pointer;
    -webkit-transition: background 0.15s ease-in-out;
    transition: background 0.15s ease-in-out;
}

.range-slider__range::-webkit-slider-thumb:hover {
    background: #00095e;
}

.range-slider__range:active::-webkit-slider-thumb {
    background: #00095e;
}

.range-slider__range::-moz-range-thumb {
    width: 20px;
    height: 20px;
    border: 0;
    border-radius: 50%;
    background: #C8102E;
    cursor: pointer;
    -moz-transition: background 0.15s ease-in-out;
    transition: background 0.15s ease-in-out;
}

.range-slider__range::-webkit-slider-thumb:hover,
.range-slider__range::-webkit-slider-thumb:focus {
    background: #C8102E;
}

.range-slider__range:active::-moz-range-thumb {
    background: #C8102E;
}

.range-slider__range:focus::-webkit-slider-thumb {
    -webkit-box-shadow: 0 0 0 3px #fff, 0 0 0 6px #C8102E;
            box-shadow: 0 0 0 3px #fff, 0 0 0 6px #C8102E;
}

.img-size {
    width: 18px !important;
    margin-top: 5px;
    margin-right: 10px;
}
.position-relative .img-size {
    position: absolute;
   top: 10px;
    left: 12px;
        width: 15px !important;
}
#LoginFormPopup label{
    color: #827F7F
}
.width-100{
    width: 100%;
}
@media (min-width: 576px)
{
#LoginFormPopup .modal-dialog {
    max-width: 991px;
    margin: 6.75rem auto;
}   
}
.forgot-pass-text:hover{
    text-decoration: underline;
    color: #c8102e;
}
#a1 p{
    color: #C8102E;
    font-size: 12px;
}.card-title {
    margin-bottom: .75rem;
    font-weight: bold;
}
.card{
    border:0px;
}
#my-a .my-fonta{
    color: #827F7F;
    font-size: 16px;
    margin: 10px 0px;
}
#my-a a{
    color: #827F7F;   
    margin-top: 10px;
}#my-a p{
    font-size: 14px;
}
.phoneaa {
    width: 17px;
    height: auto;
    margin-top: 4px;
}#partner-ab .nav-link
{
  margin-bottom: 10px;  
}
#partner-ab .img-details-wrap.card {
     border:0px;
    -webkit-box-shadow: none;
            box-shadow: none;
    height: auto;
}
#partner-ab .img-details-wrap.card img{
    width: 100%;
    height: auto;
}
.p-30{
    padding: 20px
}
.card-body2{
    padding-left: 30px;
}
.panel-title {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 16px;
    color: inherit;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: space-betweenspace-around;
        -ms-flex-pack: space-betweenspace-around;
            justify-content: space-betweenspace-around;
    position: relative;
    padding-right: 25px;
}

.panel-heading .accordion-toggle:after {
    font-family: 'Glyphicons Halflings';
    content: "\e114";
    float: right;
    color: grey;
    /* margin-left: 674px; */
    position: absolute;
    right: 0;
}
#accordion a{
    width: 100%;
    display: block;
}
.panel-title:hover >a ,.panel-title:hover >.accordion-toggle:after{
    color: #c8102e
}
.btn{
color: white;
background-color: #C8102E;
height: 40px;
width: 150px;
margin-top: -27px;
}
.pad-10{
    padding: 0px 15px;
}


/*-- 8May21 --*/
.feedback-sticky ,.feedback-sticky:hover{
    position: fixed;
    bottom: 250px;
    z-index: 9999;
    color: #010101;
    font-size: 16px;
    line-height: 22px;
    right: -40px;
    background-color: #ffffff;
    padding: 7px 24px;
    font-weight: 600;
    -webkit-transform: rotate( 270deg );
        -ms-transform: rotate( 270deg );
            transform: rotate( 270deg );
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    -webkit-box-shadow: 0px 0px 16px #00000026;
            box-shadow: 0px 0px 16px #00000026;
}
.save-cookies-section {
    position: fixed;
    bottom: 0;
    z-index: 999;
    width: 100%;
    background-color: #C8102E;
    left: 0;
    right: 0;
    padding: 12px 0;
}

.save-cookies-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}

.save-cookies-content p {
    font-size: 16px;
    line-height: 26px;
    color: #FFFFFF;
    margin-right: 20px;
}

.save-cookies-content a {
    font-size: 14px;
    line-height: 21px;
    color: #C8102E;
    background-color: #ffffff;
    padding: 6px 17px;
    border-radius: 60px;
    font-weight: bold;
}
.slider-brands-logo {
    width: 120px;
    height: 120px;
    background-color: #F8F8F8;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    border-radius: 50%;
    margin:0 auto;
}
.slider-brands { text-align: center; }
.slider-brands span {
    font-size: 16px;
    line-height: 26px;
    color: #63666A;
    margin-top: 10px;
}
.subscriber-red-section{
    background-color:#C8102E;
    padding: 28px 0;
}
.subscriber-red-section h2 { color: #ffffff; }
.subscriber-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}
.subscriber-container form {
    position: relative;
    width: 100%;
    max-width: 642px;
}
.subscriber-container form input::-webkit-input-placeholder { color: rgb(0 0 0 / 24%); }
.subscriber-container form input::-moz-placeholder { color: rgb(0 0 0 / 24%); }
.subscriber-container form input:-ms-input-placeholder { color: rgb(0 0 0 / 24%); }
.subscriber-container form input::-ms-input-placeholder { color: rgb(0 0 0 / 24%); }
.subscriber-container form input::placeholder { color: rgb(0 0 0 / 24%); }
.subscriber-container form input[type="text"] {
    font-size: 16px;
    line-height: 24px;
    width: 100%;
    padding: 16px 200px 16px 40px;
    border-radius: 90px;
    border: 0;

}
.subscriber-container form input[id="submit"] {
    position: absolute;
    right: 7px;
    top: 5px;
    border: 2px solid #C8102E;
    background-color: transparent;
    font-size: 18px;
    line-height: 22px;
    color: #C8102E;
    padding: 10px 30px;
    border-radius: 80px;
    font-weight: bold;
    -webkit-transition:0.3s all ease-in-out;
    -o-transition:0.3s all ease-in-out;
    transition:0.3s all ease-in-out;
}
.subscriber-container form input[id="submit"]:hover{ background: #c8102c; color:#ffffff; }
.footer-logo { margin-bottom: 25px; }
.header-nav.header-black-wrap { background-color: #000000; }
.header-black-wrap .toggle-icon span { background: #ffffff; }
.header-black-wrap .call-header i ,.header-black-wrap  .dropdown.country-dropdown button { color: #ffffff; }
.header-black-wrap .dropdown.country-dropdown button:before { background-image: url(../images/world-lang-invert.svg); }
.header-top-info.header-top-info-white { background-color: #ffffff; color: #999999; }
.header-top-info.header-top-info-white a { color: #000000; }
.pickup-dropdown-box-wrapper { display: -webkit-box; display: -ms-flexbox; display: flex; -ms-flex-wrap: wrap; flex-wrap: wrap; }

.pickup-dropdown-box-left { padding:38px 40px; }
.NearByLocation__currentLocation {
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #C8102E;
    cursor: pointer;
    justify-content: space-between;
}
.NearByLocation__currentLocation input[type="text"] {
    border: 0;
    font-size: 16px;
    line-height: 1.2;
    width: 50%;
    border-bottom: 2px solid rgb(0 0 0 / 30%);
}
.NearByLocation__currentLocation img { margin-right: 5px;margin-left: auto;}
.StationHistory__title {
    font-size: 18px;
    line-height: 1.4;
    font-weight: 600;
    cursor: pointer;
}
.StationHistory__stationInfo + .StationHistory__stationInfo { margin-top: 10px; }
.StationHistory__title > div {margin-top: 12px;}
.StationHistory__title1,.StationHistory__title2 {
    font-size: 20px;
    line-height: 1.5;
    font-weight: 200;
    display: flex;
    align-items: center;
    opacity:0.9;
}
.StationHistory__title1 img,.StationHistory__title2 img {
    height: auto;
    max-width: 12px;
    margin-right: 5px;
}
.StationHistory__stationInfo > div + div {margin-top: 20px;}
.pickup-dropdown-box-right { background-color: #ffffff;display: flex;flex-wrap: wrap;max-width: 50%;width:100%;padding: 0;align-items:flex-start;margin-left: 0;}
.StationDetails__wrapper { padding: 38px 50px;min-height: 250px;}
.StationDetails__wrapper h3.StationDetails__headline { font-size: 24px; line-height: 29px; }
.StationDetails__wrapper .StationDetails__subline {
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    opacity: 0.5;
}
.StationInfo__mapWrapper {
    margin-top: auto;
    margin-bottom: 0;
    width:100%;
}
.StationHistory__title1:hover{color:#c8102e;opacity:1;}
.OpeningHours__openingDay {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    color: #C8102E;
    font-size: 16px;
    line-height: 19px;
}
.OpeningHours__times { margin-left: 7px;line-height:1.6;}
.OpeningHours__openingDay + .OpeningHours__openingDay { margin-top: 5px; }
.StationDetails__openings { margin-top: 18px; }
.StationInfo__mapWrapper iframe { min-height: 260px;width:100%;}



.rental-steps-mobile-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.rental-steps-mobile-left {
    width: 50px;
    height: 100%;
    font-size: 28px;
    line-height: 28px;
    padding: 15px 15px;
    position: relative;
    margin-right: 20px;
}

section.rental-section-mobile {
    margin-top: 10px;
    max-width: 90%;
    margin: 0 auto;
    background-color: #eeeeee;
    -webkit-box-shadow: 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%), 0 2px 4px -1px rgb(0 0 0 / 20%);
            box-shadow: 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%), 0 2px 4px -1px rgb(0 0 0 / 20%);
    position: relative;
    display:none;
}

.rental-steps-mobile-center {
    padding: 10px 0;
}

.rental-steps-mobile-center h4 {
    font-size: 18px;
    line-height: 18px;
}

.rental-steps-mobile-center p {
    font-size: 12px;
    line-height: 18px;
}

.rental-steps-mobile-right {
    margin-left: auto;
    margin-right: 15px;
}

.rental-steps-mobile-right i {
    width: 25px;
    height: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    border: 1px solid;
}
.rental-steps-mobile-left::after {content: '';position: absolute;height: 60%;background-color: black;width: 1px;right: 0;top: 50%;-webkit-transform: translateY(-50%);-ms-transform: translateY(-50%);transform: translateY(-50%);}



/*-- 19May2021 --*/
.double-input-wrapper .select-wrap-section {
    width: 50%;
}
.offer-filter-wrap .filter-box{
    font-family: 'product_sansregular';
}
/* My CSS */

.time-box {
    border: 1px solid #0000001A;
    font-size: 16px;
    line-height: 24px;
    /* width: 100%; */
padding: 15px 40px !important;
    color: rgba(0, 0, 0, 0.5);
   
    border-radius:10px;
    -webkit-appearance:none;
       -moz-appearance:none;
            appearance:none;
}
.time-box:focus{outline:0;}

select.time-box {
    background-image:url('../images/time-icon.svg');
    background-repeat: no-repeat;
    background-size: 18px;
    background-position: left 17px center;
}




/* .css-19a995p-Switch,.css-1v96km2-Switch {
    width: 20px;
    height: 20px;
}

span.css-jfsaru-Switch,body .css-xjimrv-Switch,body .css-153wf2s-Switch,body .css-qnnzx6-Switch {
    width: 42px;
    height: 24px;
    border-radius: 50px;
    margin-bottom: 0;
} */

/* body .css-1v96km2-Switch {
    left: 20px;
}
body .css-jfsaru-Switch {
    background-color: #c8102e;
} */
body .custom-switch-wrap{ width: 42px;height: 24px;}
.view-details-box-wrap .custom-control-label1 {
    max-width: calc(100% - 42px);
    margin-bottom: 0;
}
body .custom-switch-wrap span:nth-child(2) {
    border-radius: 80px;
    width: 42px;
    height: 24px;
}
body .custom-switch-wrap span:last-child {
    border-radius: 50%;
    width: 20px;
    height: 20px;
}
body .custom-switch-wrap.custom-switch-active-wrap span:nth-child(2) {
    background-color: #c8102e;
}
body .custom-switch-wrap.custom-switch-active-wrap span:last-child{left: 20px;}



/* width */
.StationHistory__stationInfo::-webkit-scrollbar {
    width: 10px;
}
  
  /* Track */
  .StationHistory__stationInfo::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
  }
   
  /* Handle */
  .StationHistory__stationInfo::-webkit-scrollbar-thumb {
    background:#C8102E; 
    border-radius: 10px;
  }
  
  /* Handle on hover */
  .StationHistory__stationInfo::-webkit-scrollbar-thumb:hover {
    background:#b30000; 
  }
  .StationHistory__stationInfo {
      overflow-y:auto;
    overflow-x: hidden;
    max-height: calc(340px);
}



body .react-google-flight-datepicker .day.selected.hovered::after{background-color: #C8102E;}
body .react-google-flight-datepicker .day.selected.hovered { background: linear-gradient( 90deg , #fff 50%, #ff8397 50%); }
body .react-google-flight-datepicker .day.hovered{background-color:#ff8397;}
body .react-google-flight-datepicker .day.selected.hovered.end { background: linear-gradient( 90deg , #ff8397 50%, #fff 50%); }
body .react-google-flight-datepicker .day:hover::after{border-color:#c8102e;}
body .react-google-flight-datepicker .dialog-date-picker.hide { animation: showPopup 0.2s forwards;box-shadow:none;position: relative;}
/* body .react-google-flight-datepicker .date-picker-input{display:none;} */
body .react-google-flight-datepicker .dialog-date-picker.open{box-shadow:none;position: relative;top:0;left:0;margin-bottom: 0;}
.pikcup-time-wrap + .pikcup-time-wrap { margin-left:60px; }
.pikcup-time-wrap h3 {
    font-size: 16px;
    line-height: 1.4;
    text-transform: uppercase;
    margin-bottom: 10px;
    font-family: 'product_sansregular';
    color: #3c4043;
}
.pikcup-time-wrap ul {
    margin: 0;
    padding: 0;
    list-style: none;
}
.pikcup-time-wrap ul a {
    width: 100%;
    font-size: 16px;
    line-height: 1;
    padding: 10px 10px;
    font-family: 'product_sansregular';
    color: #3c4043;
    font-weight: normal;
}
.pikcup-time-wrap ul a:hover {
    background-color: #C8102E;
    color: #ffffff;
}

.pikcup-time-wrap .active {
    background-color: #C8102E;
    color: #ffffff;
}

.pikcup-time-list-wrap ul {
    overflow: auto;
    overflow-x: hidden;
    max-height: 260px;
}
/* width */
.pikcup-time-list-wrap ul::-webkit-scrollbar {
    width: 5px;
}
  
  /* Track */
.pikcup-time-list-wrap ul::-webkit-scrollbar-track {
    background: #f1f1f1;
}
  
  /* Handle */
.pikcup-time-list-wrap ul::-webkit-scrollbar-thumb {
    background: #C8102E;
}
  
  /* Handle on hover */
.pikcup-time-list-wrap ul::-webkit-scrollbar-thumb:hover {
    background: #5b000f;
}
body .pickup-location-box-wrap > .pickup-location-input{display:block;}
body .pickup-location-box-wrap > .pickup-location-input:nth-child(1),body .pickup-location-box-wrap > .pickup-location-input:nth-child(2) { width: 12.4%; }
.pickup-location-box-wrap > .pickup-location-input label{font-size:14px;}
.pickup-location-box-wrap > .pickup-location-input label span{display:block;font-size: 12px;line-height: 1;}
.pickup-location-box-wrap > .pickup-location-input .MuiInputBase-root input { padding: 0; font-size:14px; }
body .pickup-location-box-section .date-picker-input { display: block; }
body .pickup-location-box-section .date-picker-input { display: block; height: auto; }
body .pickup-location-box-section .date-picker-input svg.icon-arrow { min-height: auto; height: auto; }
body .pickup-location-box-section .date-picker-input button.change-date-button { height: auto; }
.pickup-location-box-wrap > .pickup-location-input select { padding: 8px 13px 8px 40px !important; border-radius: 5px; }
.pickup-location-box-wrap > .pickup-location-input input#promocode {
    padding: 12px 10px;
    height: auto;
    font-size: 16px;
    line-height: 1;
    border-radius: 5px;
    width: auto;
}
.pickup-location-box-section .pickup-location-input.group-input:nth-child(4) { width: 260px; }
.pickup-location-box-section .pickup-location-input.group-input:nth-child(5) { width: 180px; }
body .react-google-flight-datepicker .date-picker { padding: 0; }
.pickup-location-box-wrap > .pickup-location-input .MuiInputBase-root.MuiOutlinedInput-root { padding-top: 13px; padding-bottom: 13px;height: 40.88px;}
.pickup-location-box-section .pickup-location-box-wrap { align-items: flex-end; }
.MuiAutocomplete-option { font-size: 11px; line-height: 1.4; }
label.custom-control-label{font-size:15px;}
.offer-filter-wrap .filter-box.custom-control.custom-switch { max-width: 200px; }
.pickup-dropdown-box-left{width:50%;}

.pickup-return-date-wrapper .pickup-dropdown-box-left { padding: 24px 40px; width: auto; }
.pickup-dropdown-days-wrap { width: 15%; padding: 54px 40px 0; }
.pickup-return-date-wrapper .pickup-dropdown-box-right { width: 20%; padding: 54px 40px 0; }
.MuiAlert-message { font-family: 'product_sansregular'; }
.featured-rent-price-radio .featured-rent-radio {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.pickup-location-box-section .pickup-location-box-wrap .pickup-location-input.group-input:nth-child(1), .pickup-location-box-section .pickup-location-box-wrap .pickup-location-input.group-input:nth-child(2) {
    width: 15%;
}
body .react-google-flight-datepicker .date:focus, .react-google-flight-datepicker .date.is-focus { background-color: transparent !important; }
body .react-google-flight-datepicker .date:focus::after, .react-google-flight-datepicker .date.is-focus::after { border: 0 !important; margin: 0 !important; }
.pickup-location-box-section .pickup-location-box-wrap .pickup-location-input.group-input:nth-child(4) { width: 210px; }
.pickup-location-box-section .pickup-location-box-wrap .pickup-location-input.group-input:nth-child(3) { width: 330px; }
.MuiAutocomplete-option{
    font-family: 'product_sansregular';
    font-size:12px;
}
body .pickup-location-box-section .react-google-flight-datepicker .dialog-date-picker.open,body .pickup-location-box-section .react-google-flight-datepicker .dialog-date-picker.hide { position: absolute; top: 100%; left: 0px; }
.react-google-flight-datepicker .selected-date{padding:13px 0;}
.upgrade-option-modal-content .vehicle-design-upgrade-box {
    margin: 10px 0;
    border-radius: 0;
    padding:20px;
}

.upgrade-option-modal-content .vehicle-design-upgrade-box .vehicle-box-design {
    border-radius: 0;
} 

.special-offer-page-wrap h2.border-botom {
    font-size: 30px;
    line-height: 1;
}
.special-offer-page-wrap .special-offer-banner {
    position: relative;
    border-radius: 20px;
    overflow: hidden;
}
.book-deal-content-section h6 {
    font-size: 16px;
    line-height: 1;
    color: #000000;
    margin-bottom: 0;
}
.book-deal-content-section .select-wrap-dropdown,.pickup-dropoff-date-wrap .date-picker-input {
    width:100% !important;
    max-width: 100%;
}


.book-deal-content-section .select-wrap-dropdown input {
    color: black;
    font-size: 14px;
    line-height: 1.3;
}
.special-offer-start-end-date-wrap {
    display: flex;
    align-items: flex-start;
    margin: 20px 0;
}
.special-offer-start-end-date-col + .special-offer-start-end-date-col {
    margin-left: 30px;
}
.pickup-drop-location-wrap .MuiInputBase-root.MuiAutocomplete-inputRoot,.pickup-dropoff-date-wrap .react-google-flight-datepicker .date-picker-input,.promo-code-wrap input.form-control,.pickup-dropoff-date-wrap1 .react-datepicker__input-container input[type="text"]{
    border: 1px solid #827F7F;
    border-radius: 10px;
    font-size: 18px;
    line-height: 24px;
    padding: 16px 16px 16px 46px;
    background-image:url('../images/location-icon.svg');
    background-repeat:no-repeat;
    background-position:left 20px center;
    height:auto;
}
.pickup-dropoff-date-wrap1 .react-datepicker__input-container input[type="text"]{
    background-image:url('../images/calendar.svg');
    background-size:20px;
}
.pickup-dropoff-date-wrap1 button.react-datepicker__close-icon::after {
    background-color: #C8102E;
}
.pickup-drop-time-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.pickup-dropoff-date-wrap .react-google-flight-datepicker .date-picker-input,.promo-code-wrap input.form-control{background-image:none;padding:16px;}
.pickup-drop-location-wrap .MuiInputBase-root::before,.pickup-drop-location-wrap .MuiInput-underline:after { display: none; }
.pickup-drop-location-wrap .Mui-focused .MuiInputBase-root{
    border-color: #006FF7;
    -webkit-box-shadow: 0px 0px 16px #006ff740;
    box-shadow: 0px 0px 16px #006ff740;
}
.pickup-drop-time-wrap select.time-box{
    border: 1px solid #827F7F;
    border-radius: 10px;
    font-size: 18px;
    line-height: 24px;
    padding: 11px 16px;
    width:49%;
}
.pickup-dropoff-date-wrap .date-picker-input svg.icon-calendar { fill: #C8102E; }
.book-deal-content-section .MuiAutocomplete-input, .book-deal-content-section .selected-date { padding: 0 !important; }
.pickup-dropoff-date-wrap .react-google-flight-datepicker .date-picker-input { padding: 6px 16px; }
.pickup-dropdown-box-wrapper.pickup-return-date-wrapper .date-picker-input,.pickup-dropdown-box-wrapper.pickup-return-date-wrapper .dialog-header { display: none; }
.pickup-dropdown-box-wrapper.pickup-return-date-wrapper .dialog-content { border-top: 0; padding-top: 0;border-bottom:0;}
.first-row-img img {
    height: auto;
    width: 100%;
    max-width: 100%;
    margin: 0;
}

.form-login-right {
    padding:0 15px 15px;
}

.form-login-right button.close {
    position: static;
    padding: 0;
    margin: 0;
    line-height: 37px;
}
.form-login-right .forgot-pass-text {
    display: inline-block;
    float: none;
}
.login-container {
    border-radius: 15px;
    overflow: hidden;
}
.register-form-page {
    background-color: #F8F8F8;
    padding:30px 0;
}
.register-form-page .form-register {
    max-width: 460px;
    margin: 0 auto;
}

.register-form-page .form-register h3 {
    font-size: 32px;
    line-height: 1.2;
    margin-bottom: 0;
}

.register-form-page .form-register h5 {
    font-size: 24px;
    line-height: 1.2;
    margin: 20px 0 10px;
}.register-form-page .reg-main-img img {
    width: 100%;
    height: auto;
    border-radius: 15px;
}

.register-form-page .form-register label {
    font-size: 14px;
    line-height: 1.4;
}
.register-form-page .form-register .form-group {
    margin: 20px 0;
}
.register-form-page .custom-checkbox label::before,.register-form-page .custom-checkbox label::after {
    top: 0.2px;
}
.half-width-bttn {
    text-align: right;
}

.half-width-bttn .rounded-bttn.rounded-bttn-transparent {
    border-radius: 6px;
    width: 85%;
}
.login-register-wrap h6 {
    width: 100%;
}
.login-register-wrap h6 + h6 { margin-bottom: 0; }
.login-wrapper .logout-bttn {
    color: #000000;
    font-size: 14px;
    line-height: 1;
}
.login-wrapper h6.user-name {
    font-size: 15px;
    line-height: 1;
    margin-bottom: 5px;
}
.pickup-dropoff-date-wrap1 .react-datepicker-wrapper,.pickup-dropoff-date-wrap1 .react-datepicker__input-container { display: block; }
.pickup-dropoff-date-wrap1 .react-datepicker__input-container input[type="text"] { width: 100%; }


body .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range,body .react-datepicker__day--in-range,body .react-datepicker__month-text--selected,body .react-datepicker__month-text--in-selecting-range,body .react-datepicker__month-text--in-range,body .react-datepicker__quarter-text--selected,body .react-datepicker__quarter-text--in-selecting-range,body .react-datepicker__quarter-text--in-range,body .react-datepicker__year-text--selected,body .react-datepicker__year-text--in-selecting-range,body .react-datepicker__year-text--in-range,body .react-datepicker__day--keyboard-selected:hover,body .react-datepicker__month-text--keyboard-selected:hover,body .react-datepicker__quarter-text--keyboard-selected:hover,body .react-datepicker__year-text--keyboard-selected:hover,body .react-datepicker__day--selected:hover,body .react-datepicker__day--in-selecting-range:hover,body .react-datepicker__day--in-range:hover,body .react-datepicker__month-text--selected:hover,body .react-datepicker__month-text--in-selecting-range:hover,body .react-datepicker__month-text--in-range:hover,body .react-datepicker__quarter-text--selected:hover,body .react-datepicker__quarter-text--in-selecting-range:hover,body .react-datepicker__quarter-text--in-range:hover,body .react-datepicker__year-text--selected:hover,body .react-datepicker__year-text--in-selecting-range:hover,body .react-datepicker__year-text--in-range:hover{
    background-color:#C8102E;
}

body .react-datepicker__day--keyboard-selected,body .react-datepicker__month-text--keyboard-selected,body .react-datepicker__quarter-text--keyboard-selected,body .react-datepicker__year-text--keyboard-selected{
    background-color:#f85974;
}
/* header.header-nav.header-invert h6.logout-bttn {
    color: #ffffff;
    opacity: 0.6;
} */
.pickup-dropdown-box-wrapper.pickup-return-date-wrapper .dialog-footer { display: none; }
.react-google-flight-datepicker .day.selected::after{background-color: #c8102e;}
main.main-content {
    background-image: url('../images/login-bg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 100vh;   
    width:100%;
}
.login-page main.main-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
}
.login-form-wrapper {
    max-width: 546px;
    width: 100%;
    text-align: center;
    margin: 50px 0;
}
.login-form-wrapper .login-form-head {
    background-color: #ffffff;
    padding:17px 10px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}
.login-form-wrapper .login-form-content {
    background-color: #F5F5F5;
    padding: 50px 100px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}
.login-form-wrapper .login-form-content h2 {
    margin-bottom: 70px;
}
.form-group-design input.form-input {
    padding: 3px 0;
    height: auto;
    width: 100%;
    border: 0;
    background-color: transparent;
    border-bottom: 1px solid #E2E2E2;
    font-size: 16px;
    line-height: 22px;
    color: #19171A;
    font-family: 'open_sanssemibold';
    transition:0.3s all ease-in-out;
}
.form-group-design input.form-input:focus{
    border-bottom-color:#9CA2B1;
}
.form-group-design label {
    margin-bottom: 0;
    color: #9CA2B1;
    font-size: 12px;
    line-height: 18px;
    font-family: 'open_sansregular';
}
a.forgot-psw-link, a.new-mbrshp-link {
    display: block;
}
.form-group-design input.form-input[type="email"] {
    background-image: url(../images/envelope.svg);
    background-repeat: no-repeat;
    background-position: center right 2px;
}
.form-group-design input.form-input[type="password"] {
    background-image: url(../images/key.svg);
    background-repeat: no-repeat;
    background-position: center right ;
}
.form-group-design {
    margin-bottom: 27px;
}
form.login-form {
    margin-bottom: 80px;
}
a.new-mbrshp-link {
    margin-top: 20px;
}
p.copyright-content {
    margin-top: 20px;
}
.login-form-radius {
    border-radius: 8px;
    box-shadow: 0px 1px 22px #BECDFF;
}


*,
*::before,
*::after {
  box-sizing: border-box;
}

 
.timeline_IG {
  position: relative;
  width: 100%;
  max-width: 1140px;
  margin: 0 auto;
  padding: 15px 0;
}

.timeline_IG::after {
  content: '';
  position: absolute;
  width: 2px;
  background: #006E51;
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -1px;
}

.container_IG {
  padding: 15px 30px;
  position: relative;
  background: inherit;
  width: 50%;
}

.container_IG.left {
  left: 0;
}

.container_IG.right {
  left: 50%;
}

.container_IG::after {
  content: '';
  position: absolute;
  width: 16px;
  height: 16px;
  top: calc(50% - 8px);
  right: -8px;
  background: #ffffff;
  border: 2px solid #006E51;
  border-radius: 16px;
  z-index: 1;
}

.container_IG.right::after {
  left: -8px;
}

.container_IG::before {
  content: '';
  position: absolute;
  width: 50px;
  height: 2px;
  top: calc(50% - 1px);
  right: 8px;
  background: #006E51;
  z-index: 1;
}

.container_IG.right::before {
  left: 8px;
}

.container_IG .date {
  position: absolute;
  display: inline-block;
  top: calc(50% - 8px);
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  color: #006E51;
  text-transform: uppercase;
  letter-spacing: 1px;
  z-index: 1;
}

.container_IG.left .date {
  right: -75px;
}

.container_IG.right .date {
  left: -75px;
}

.container_IG .icon {
  position: absolute;
  display: inline-block;
  width: 40px;
  height: 40px;
  padding: 9px 0;
  top: calc(50% - 20px);
  background: #F6D155;
  border: 2px solid #006E51;
  border-radius: 40px;
  text-align: center;
  font-size: 18px;
  color: #006E51;
  z-index: 1;
}

.container_IG.left .icon {
  right: 56px;
}

.container_IG.right .icon {
  left: 56px;
}

.container_IG .content_IG {
  padding: 30px 90px 30px 30px;
  background: #F6D155;
  position: relative;
  border-radius: 0 500px 500px 0;
}

.container_IG.right .content_IG {
  padding: 30px 30px 30px 90px;
  border-radius: 500px 0 0 500px;
}

.container_IG .content_IG h2 {
  margin: 0 0 10px 0;
  font-size: 18px;
  font-weight: normal;
  color: #006E51;
}

.container_IG.content_IG p {
  margin: 0;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
}

@media (max-width: 767.98px) {
  .timeline_IG::after {
    left: 90px;
  }

  .container_IG {
    width: 100%;
    padding-left: 120px;
    padding-right: 30px;
  }

  .container_IG.right {
    left: 0%;
  }

  .container_IG.left::after, 
  .container_IG.right::after {
    left: 82px;
  }

  .container_IG.left::before,
  .container_IG.right::before {
    left: 100px;
    border-color: transparent #006E51 transparent transparent;
  }

  .container_IG.left .date,
  .container_IG.right .date {
    right: auto;
    left: 15px;
  }

  .container_IG.left .icon,
  .container_IG.right .icon {
    right: auto;
    left: 146px;
  }

  .container_IG.left .content,
  .container_IG.right .content {
    padding: 30px 30px 30px 90px;
    border-radius: 500px 0 0 500px;
  }
}

:root {
    --amplify-primary-color: #f05773;
    --amplify-primary-tint: #f05773;
    --amplify-primary-shade: #f05773;
  }